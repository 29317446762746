import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
const Menu = ({ route }) => {
  return (
    <SMenu>
      <MenuButton>{route.name}</MenuButton>
      <SubRoutesContainer>
        {route.subRoutes.map((subRoute) => (
          <SubRoute to={subRoute.link} key={subRoute.name}>
            {subRoute.name}
          </SubRoute>
        ))}
      </SubRoutesContainer>
    </SMenu>
  );
};

export default Menu;

const SubRoutesContainer = styled.div`
  position: absolute;
  min-width: 32rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  left: -1rem;
  visibility: hidden;
  opacity: 0;
  border-radius: 1rem;
  
  &:hover {
    width: 100%;
    transition: all 0.3s ease-in;
    color: var(--white)
    background-color: var(--main-color);
    cursor: pointer;
  } 
`;

const SMenu = styled.div`
  position: relative;
  display: inline-block;

  &:hover ${SubRoutesContainer} {
    visibility: visible;
    opacity: 1;
    color: var(--white)
    background-color: var(--main-color);
    cursor: pointer;
  }
`;

// DROPDOWN NAVBAR
const MenuButton = styled.div`
  font-size: 18px;
 
  &:hover {
    transition: 0.5s ease;
    color: var(--yellow);
    background-color: var(--main-color);
    box-shadow: 0px 0px 10px white;
    border-radius: 2px;
    cursor: pointer;
  }
  
 @media screen and (max-width: 1200px) {
     font-size: 16px;
     font-weight: bold;
`;


// SUBMENU DISPLAY
const SubRoute = styled(Link)`
  text-decoration: none;
  background-color: var(--main-color);
  color: var(--white);
  padding: 1rem;
  width: 100%;
  margin-top:0;
  font-size: 16px;
  z-index:1000;

    &:hover {
    transition: all 0.2s ease-out;
    color: var(--yellow);
    background-color: var(--main-color);
    box-shadow: 0px 0px 10px white;  
    cursor: pointer;
  }
`;
