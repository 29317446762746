import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ExpandMenu from "./ExpandMenu";

const Drawer = ({ isOpen, toggleDrawer, routes }) => {

  return (
    <>
      {isOpen && <Backdrop onClick={toggleDrawer} />}
      <SDrawer isOpen={isOpen}>
        <RightNav>
          <NavRoutes >
            {routes.map((route) => {
              if (route.subRoutes) {
                return <ExpandMenu
                  route={route}
                  key={route.name}
                  onClick={toggleDrawer}
                />;
              }
              return (
                <NavRoute
                  onClick={toggleDrawer}
                  to={route.link}
                  key={route.name}
                >
                  {route.name}
                </NavRoute>
              );
            })}
          </NavRoutes>
        </RightNav>
      </SDrawer>
    </>
  );
};

export default Drawer;

const Backdrop = styled.div`
  height: 100%;
  width: 100%;
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.3s ease;
`;
const SDrawer = styled.div`
  z-index: 150;
  position: absolute;
  top: 50px;
  left: 0;
  height: 100vh;
  width: 40%;
  color: var(--white);
  background-color: var(--main-color);
  transition: 0.3s ease;
  padding: 0.5rem;  
  transform: translateX(${(props) => (props.isOpen ? "0" : "-100%")});
   
   &:hover {
    transition: all 0.2s ease-out;
    color: var(--white);
    background-: var(--main-color);
    box-shadow: 0px 0px 10px white;
    cursor: pointer;
  }

  @media screen and (min-width: 768px) {
    display: none;
  }

`;

const RightNav = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;  
`;

const NavRoutes = styled.div``;
const NavRoute = styled(Link)`
  display: flex;
  text-decoration: none;
  color: white;
  font-size: 18px;
  padding: 0.5rem;

   &:hover {
    transition: all 0.2s ease-out;
    color: var(--white);
    background-: var(--main-color);
    box-shadow: 0px 0px 10px white;
    cursor: pointer;
  }
`;
