import React from 'react'
import { motion } from "framer-motion";
import './Flyers.css'

const Flyers = () => {
    // Object array of sliding gallery pages data
    const pages = [
        // {
        //     index: 1,
        //     src: '/images/logo/crysec.png',
        //     // background color of the page
        //     background: "#1e1e1e",
        // },
        {
            index: 2,
            src: '/images/logo/crysec_prices.jpeg',
            background: "#fcfcfc",
        },
        // {
        //     index: 3,
        //     src: '/images/logo/flyers.jpeg',
        //     background: "#bcbcbc",
        // },
    ];

    return (
        <div className='container'>
        <motion.div className="carousel">
            <motion.div
                animate={{
                    scale: [1, 1, 1, 1, 1],
                    rotate: [0, 30, 60, 240, 360],
                }}
                drag="x"
                dragConstraints={{ right: 0, left: -1100 }}
                dragTransition={{ bounceStiffness: 600, bounceDamping: 8 }}
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 2 }}
            >
                {pages.map((image) => {
                    return (
                        <motion.div
                            whileHover={{ scale: 1.2 }}
                            whileTap={{ scale: 2 }}
                            className="item"
                            transition={{ duration: 3 }}
                            key={image}
                        >
                            <img
                                src={image.src}
                                alt="image"
                            />
                        </motion.div>
                    );
                })}
            </motion.div>
        </motion.div>
        </div>
    );
}

export default Flyers

