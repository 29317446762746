import useRegistrationContext from "../../../hooks/useRegistrationContext"
import { useQuery } from 'react-query';
import {
    getRelationship
} from "../../../api/apiServices";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useEffect, useRef } from "react";

const NextOfKin = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    const inputRef = useRef();
    const { data, handleOnPhoneInput, handleChange, handlePhoneInput } = useRegistrationContext()

    useEffect(() => {
        inputRef.current.focus();
    }, [])

    const {
        relation
    } = useQuery('relation', getRelationship);


    const content = (
        <>
            <div className="form-group row">
                <fieldset className="col-sm-6">
                    <div className="form-group row">
                        <label htmlFor="next_of_kin" className="col-sm-3 col-form-label">Next of Kin<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <input
                                ref={inputRef}
                                type="text"
                                id="next_of_kin"
                                placeholder='Name of Next of Kin'
                                autoComplete="off"
                                style={{ textTransform: "uppercase" }}
                                onChange={handleChange}
                                required
                                value={data.nkinNextOfKin}
                                name="nkinNextOfKin"
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="kin_contact_number" className="col-sm-3 col-form-label">Contact Number<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <PhoneInput
                                type="tel"
                                id="kin_contact_number"
                                inputStyle={{ width: '100%', height: "34px" }}
                                country={"gh"}
                                countryCodeEditable={false}
                                placeholder='nkinMobileNumber'
                                enableSearch={true}
                                value={data.nkinMobileNumber}
                                name="nkinMobileNumber"
                                onChange={(e) => handlePhoneInput(e)}
                                onKeyDown={(e) => handleOnPhoneInput(e)}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="kin_relationship" className="col-sm-3 col-form-label">Relationship<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <select
                                type="text"
                                id="kin_relationship"
                                placeholder='Relationship'
                                autoComplete="off"
                                style={{ textTransform: "uppercase" }}
                                onChange={handleChange}
                                required
                                value={data.nkinRelationship}
                                name="nkinRelationship"
                                className='form-control'
                            >
                                <option>Select Option</option>
                                <option value={"WIFE"}>WIFE</option>
                                <option value={"HUSBAND"}>HUSBAND</option>
                                <option value={"SON"}>SON</option>
                                <option value={"DAUGHTER"}>DAUGHTER</option>
                                <option value={"FATHER"}>FATHER</option>
                                <option value={"MOTHER"}>MOTHER</option>
                                <option value={"BROTHER"}>BROTHER</option>
                                <option value={"SISTER"}>SISTER</option>
                                <option value={"COUSIN"}>COUSIN</option>
                                <option value={"NEPHEW"}>NEPHEW</option>
                                <option value={"UNCLE"}>UNCLE</option>
                                <option value={"AUNT"}>AUNT</option>
                                <option value={"GRANDFATHER"}>GRANDFATHER</option>
                                <option value={"GRANDMOTHER"}>GRANDMOTHER</option>
                            </select>
                        </div>
                    </div>
                </fieldset>
            </div>
        </>
    )

    return content
}
export default NextOfKin