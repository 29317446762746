import React from 'react'
import SendMessages from "./SendMessage";
import CreatorLink from "./Layout/CreaterLink";
import './Whatsapp.css'

const WhatsappMsg = () => {
    
    
    const whatsApp = (
        <div className='flex_middle'>
            <CreatorLink />
            <SendMessages />
         </div>
    )
    return (
      <>
            <section className="content-header">
                <h1><i className="fas fa-users"></i> Whatsapp Message</h1>
            </section>
            <section className="content">
                <div className="box">
                    <div className="box-body">
                        <div className="panel panel-default panel-br-4">
                            <div className="panel-heading">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h4 className="panel-title">
                                           
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-body">
                                <div className="wrapper-container">
                                    {whatsApp}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>  
        
    );
}

export default WhatsappMsg
