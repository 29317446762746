// import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Menu from "./Menu";
// import AuthContext from "../../../../context/AuthProvider";

const Navbar = ({ toggleDrawer, routes }) => {
  // const [click, setClick] = useState(false);

  // const handleClick = () => setClick(!click);


  return (
    <SNavbar>
      <DrawerButton onClick={toggleDrawer} >
        <i className={toggleDrawer ? "fas fa-bars" : "fas fa-times"} ></i>
      </DrawerButton>
      <NavContainer >
           <div className="left-navbar">
            <div className="company-logo">
              <img src="/images/logo/crysec.png" alt="Proforum" />
            </div>
            <div className="company-name">
              <h2>CRYSEC SERVICES LTD</h2>
              <p>Your Security Our Concern</p>
            </div>
          </div>
        <RightNav >
          <NavRoutes >
            {routes.map((route) => {
              if (route.subRoutes) {
                return <Menu route={route} key={route.name} />;
              }
              return (
                <NavRoute to={route.link} key={route.name} >
                  {route.name}
                </NavRoute>
              );
            })}
          </NavRoutes>
        </RightNav>
      </NavContainer>
    </SNavbar>
  );
};

export default Navbar;

const DrawerButton = styled.div`
 display: none;
 @media screen and (max-width: 850px){
   display: block;
    position: absolute;
    top: 70px;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 4rem;
    cursor: pointer;
    width: 30px;
    height: 10px;
    color: var(--white);
  }
`;

const SNavbar = styled.nav`
  background-color: var(--main-color);
`;

const NavContainer = styled.div`
  margin-right: 20px;
  padding: 1rem;
  height: 130px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  color: var(--white);
  background-color:var(--main-color);
  
  @media screen and (max-width: 768px) {
    align-items: flex-start;  
  }
`;


const RightNav = styled.div`
  display: flex;
  gap: 2rem;
`;

const NavRoutes = styled.div`
 
@media screen and (max-width: 850px) {
    display: none;
  }

  display: flex;
  justify-content: flex-start;
  gap: 2rem;
  font-size: 2rem;
  align-items: center;
`;

// NAVBAR WITHOUT DROPDOWN
const NavRoute = styled(Link)`
  text-decoration: none;
  color: var(--white);
  padding: 0.5rem;
  font-size: 20px;
  font-weight: bold;
  border-radius: 5px;
  transition: all 0.5s ease;

  &:hover {
    transition: all 0.2s ease-out;
    color: var(--main-color);
    background-color: var(--white);
    box-shadow: 0px 0px 10px white;
  }

 @media screen and (max-width: 1200px) {
     font-size: 16px;
     font-weight: bold;
     white-space: nowrap;
  }
`;

