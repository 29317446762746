import { createContext, useState } from "react"
import { useMutation } from 'react-query';
import {
    generalSearch,
} from "../api/apiServices";

const GeneralSearchContext = createContext({})

export const GeneralSearchProvider = ({ children }) => {

    const [searchResult, setSearchResult] = useState({
        search: false,
        tenant: [],
        tenant_code: "",
        contact_number: "",
        indenture_number: "",
        first_name: "",
        last_name: "",
    })

    const [phone_input_name, setPhoneInputName] = useState("")

    const searchMutation = useMutation(generalSearch, {
        onSuccess: async (res) => {
            if (res.status === true) {
                setSearchResult(prevData => ({
                    ...prevData,
                    tenant: res.tenant,
                    search: true,
                    tenant_code: "",
                    contact_number: "",
                    indenture_number: "",
                    first_name: "",
                    last_name: "",
                }))
            } else {
                console.log("No Data found")
                setSearchResult(prevData => ({
                    ...prevData,
                    tenant: [],
                    search: false,
                    tenant_code: "",
                    contact_number: "",
                    indenture_number: "",
                    first_name: "",
                    last_name: "",
                }))
                return false
            }
        },
        onError: async (err) => {
            console.log(err)
        }
    })

    const handleSearch = async (e) => {
        e.preventDefault();

        const searchData = {
            tenant_code: searchResult.tenant_code,
            contact_number: searchResult.contact_number,
            indenture_number: searchResult.indenture_number,
            first_name: searchResult.first_name,
            last_name: searchResult.last_name,
        }
        searchMutation.mutate(searchData)
    }

    const handlePhoneInputName = (e) => {
        const name = e.target.placeholder
        setPhoneInputName(name)
    }

    const handlePhoneChange = (e) => {
        const value = e
        const name = phone_input_name ? phone_input_name : undefined
        setSearchResult(prevData => ({
            ...prevData,
            [name]: value
        }))
    }

    const handleChange = e => {
        const name = e.target.name
        const value = e.target.value
        setSearchResult(prevData => ({
            ...prevData,
            [name]: value
        }))
    }

    const handleCancel = (e) => {
        setSearchResult(prevData => ({
            ...prevData,
            search: false,
            tenant: [],
        }))
    }

    return (
        <GeneralSearchContext.Provider value={{
            searchResult,
            setSearchResult,
            handleSearch,
            handlePhoneInputName,
            handlePhoneChange,
            handleChange,
            handleCancel
        }}>
            {children}
        </GeneralSearchContext.Provider>
    )
}

export default GeneralSearchContext 