import React, { useEffect, useRef } from "react";
import useRegistrationContext from "../../../hooks/useRegistrationContext"
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { Link } from "react-router-dom";

const ContactInformation = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    const inputRef = useRef();
    const { data, handleOnPhoneInput, handleChange, handlePhoneInput } = useRegistrationContext()
    useEffect(() => {
        inputRef.current.focus();
    }, [])

    const content = (
        <>
            <div className="form-group row">
                <fieldset className="col-sm-6">
                    <div className="form-group row">
                        <label htmlFor="car_number" className="col-sm-4 col-form-label">Car Number<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-8">
                            <input
                                ref={inputRef}
                                type="text"
                                id="car_number"
                                placeholder='Car Number'
                                autoComplete="off"
                                onChange={handleChange}
                                style={{ textTransform: "uppercase" }}
                                required
                                value={data.contactCarNumber}
                                name="contactCarNumber"
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="mobile_number" className="col-sm-4 col-form-label">Mobile Number<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-8">
                            <PhoneInput
                                jumpCursorToEnd={true}
                                type="tel"
                                id="mobile_number"
                                inputStyle={{ width: '100%', height: "34px" }}
                                country={"gh"}
                                countryCodeEditable={false}
                                placeholder='contactMobileNumber'
                                enableSearch={true}
                                value={data.contactMobileNumber}
                                name="contactMobileNumber"
                                onChange={(e) => handlePhoneInput(e)}
                                onKeyDown={(e) => handleOnPhoneInput(e)}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="email" className="col-sm-4 col-form-label">Email Address<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-8">
                            <input
                                type="email"
                                id="email"
                                placeholder='Email Address'
                                autoComplete="off"
                                onChange={handleChange}
                                required
                                value={data.contactEmail}
                                name="contactEmail"
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="spouse_mobile_number" className="col-sm-4 col-form-label">Spouse Mobile Number</label>
                        <div className="col-sm-8">
                            <PhoneInput
                                type="tel"
                                id="spouse_contact_number"
                                inputStyle={{ width: '100%', height: "34px" }}
                                country={"gh"}
                                countryCodeEditable={false}
                                placeholder="contactSpouseMobileNumber"
                                enableSearch={true}
                                value={data.contactSpouseMobileNumber}
                                name="contactSpouseMobileNumber"
                                onChange={(e) => handlePhoneInput(e)}
                                onKeyDown={(e) => handleOnPhoneInput(e)}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="gps_address" className="col-sm-4 col-form-label">GPS Address</label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                id="gps_address"
                                placeholder='GPS Address'
                                autoComplete="off"
                                style={{ textTransform: "uppercase" }}
                                onChange={handleChange}
                                value={data.contactGpsAddress}
                                name="contactGpsAddress"
                                className='form-control'
                            />
                        </div>
                    </div>
                </fieldset>
            </div >
        </>
    )

    return content
}
export default ContactInformation