import React, { useState } from 'react'
import "./Driver.css"

const DriverCardLeft = (props) => {

    return (
        <>
            <div className="driver-card-left">
                <h2>{props.title}</h2>
                <img src={props.imageUrl} alt={props.imageAlt} />
                <p>Driver Code : {props.code}</p>
            </div>
        </>
    )
}

export default DriverCardLeft