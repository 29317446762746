import React from "react"
import Back from "../../common/Back"
import Heading from "../../common/Heading"
import img from "../../main/images/about.jpg"
import "./about.css"
import { useNavigate } from "react-router-dom"

const MoreAbout = () => {
  const navigate = useNavigate();

  const handleAboutUs = () => {
    navigate(`/about`);
  }
  return (
    <>
      <section className='about'>
        <Back name='' title='About Us - Who We Are?' cover={img} />
        <div className='container flex mtop'>
          <div className='left row'>
            <Heading title='Our Mission'/>

            <p>We are committed to safeguarding the well-being of drivers by providing cutting-edge security tecknology and a swift response system. Our collaboration with the Ghana Police ensures a robust safety network for drivers across the nation</p>
                       
            <Heading title='Our Vision'  />

            <p>To be a pioneering force in enhancing driver safety. By offering a holistic security solution and collaborating with law enforcement, we strive to create a safer driving experince for all, contributing to the overall well-being of the community.</p>
            
            <button type="button" onClick={handleAboutUs} className='btn2'>Back</button>
          </div>
          <div className='right row'>
            <img src='/images/logo/crysec_info.jpeg' alt='' />
            <hr />
            <img src='/images/logo/more_info.jpeg' alt='' />
          </div>
        </div>
      </section>
    </>
  )
}

export default MoreAbout
