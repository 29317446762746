import React from "react";
import { Outlet } from 'react-router-dom';
// import Header from "../../main/header/Header";
import Footer from "../../main/footer/Footer";
import Navigation from "../navigation/MainNavbar/Navigation";
import MainFooter from "../../main/footer/MainFooter";


const MainLayout = () => {
  return (
    <>
      <Navigation />
      <div className="App">
        <Outlet />
      </div>
      {/* <Footer /> */}
      <MainFooter />
    </>
  );
};

export default MainLayout;
