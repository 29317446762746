import React, { useContext } from 'react'
import AuthContext from "../../../context/AuthProvider";
import { SuperAdminSidebarData } from './SuperAdminSidebarData';
import { SystemAdminSidebarData } from './SystemAdminSidebarData';
import { SuperUserSidebarData } from './SuperUserSidebarData';
import { OperationsSidebarData } from './OperationsSidebarData';
import { AccountsSidebarData } from './AccountsSidebarData';
import { LegalSidebarData } from './LegalSidebarData';
import { SystemUserSidebarData } from './SystemUserSidebarData';
import { Link } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";

const Sidebar = ({ allowedRoles }) => {
    let menuItems = []

    const { auth } = useContext(AuthContext)

    const decoded = auth?.accessToken
        ? jwtDecode(auth.accessToken)
        : undefined

    const role = decoded?.UserInfo?.role || []
    
    role === allowedRoles.SuperAdmin
        ? menuItems = SuperAdminSidebarData
        : role === allowedRoles.SystemAdmin
            ? menuItems = SystemAdminSidebarData
            : role === allowedRoles.SuperUser
                ? menuItems = SuperUserSidebarData
                : role === allowedRoles.SuperUser
                    ? menuItems = SuperUserSidebarData
                    : role === allowedRoles.Operations
                        ? menuItems = OperationsSidebarData
                        : role === allowedRoles.Accounts
                            ? menuItems = AccountsSidebarData
                            : role === allowedRoles.Legal
                                ? menuItems = LegalSidebarData
                                : menuItems = []


    return (
        <div className="main-sidebar">
            <section className="sidebar">
                <ul className="sidebar-menu">
                    {menuItems?.map((item, index) =>
                        !item.subNav
                            ?
                            <li className="treeview" key={index}>
                                <Link to={item.path}>
                                    <span>{item.title}</span>
                                </Link>
                            </li>
                            :
                            <li className="treeview" key={index}>
                                <Link item={item.path}>
                                    <span>{item.title}</span>
                                    <span className="pull-right-container">
                                        <i className="fa fa-plus pull-right"></i>
                                    </span>
                                </Link>
                                <ul className="treeview-menu">
                                    {item.subNav.map((item, index) =>
                                        !item.subNav
                                            ?
                                            <li className="treeview" key={index}>
                                                <Link to={item.path}>
                                                    <i className="fa fa-minus"></i>
                                                    <span>{item.title}</span>
                                                </Link>
                                            </li>
                                            :
                                            <li className="treeview" key={index}>
                                                <Link item={item.path}>
                                                    <i className="fa fa-minus"></i>
                                                    <span>{item.title}</span>
                                                    <span className="pull-right-container">
                                                        <i className="fa fa-plus pull-right"></i>
                                                    </span>
                                                </Link>
                                                <ul className="treeview-menu">
                                                    {item.subNav.map((item, index) =>
                                                        <li className="treeview" key={index}>
                                                            <Link to={item.path}>
                                                                <i className="fa fa-minus"></i>
                                                                {item.title}
                                                            </Link>
                                                        </li>
                                                    )}
                                                </ul>
                                            </li>
                                    )}
                                </ul>
                            </li>
                    )}
                </ul>
            </section>
        </div >
    )
}

export default Sidebar


