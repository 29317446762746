import { useEffect } from "react";
import useRegistrationContext from "../../../hooks/useRegistrationContext"
import Checkbox from "../../common/checkbox/Checkbox"
import "react-phone-input-2/lib/bootstrap.css";
import { Link } from "react-router-dom";

const TermsConditions = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const { handleAgreement, } = useRegistrationContext()

    const content = (
        <>
            <div className="terms-conditions" >
                <h3>TERMS AND CONDITIONS</h3>
                <p>Registration to use our services is subject to the Terms and Conditions  as located at</p>
                <p>
                    <Link
                        rel="noreferrer"
                        target="_blank"
                        download={"User Agreement.pdf"}
                        to={`/documents/agreement.pdf`}
                    > {`${process.env.REACT_APP_BASE_URL}/agreement.pdf`}
                        ("User Agreement")
                    </Link>
                </p>
                <p> By submitting your registration form, you agree to be bound by the terms of the User Agreement.</p>
            <div className="panel-footer">
                <div className="checkbox-wrapper">
                    <Checkbox
                        label="I accept the term and conditions of the Agreement"
                        checked={true}
                        handleAgreement={handleAgreement}
                    />
                </div>
            </div>
            </div>           
        </>
    )

    return content
}
export default TermsConditions