export const AccountsSidebarData = [
    {
        title: 'Dashboard',
        path: '/admin/dashboard',
    },
    {
        title: 'Tenants Management',
        subNav: [
            {
                title: 'Registration',
                path: '/#',
                subNav: [
                    {
                        title: 'Sales & Regularization',
                        path: '/sales_regularization',
                    },
                ],
            },
            {
                title: 'Enquiry',
                path: '/#',
                subNav: [
                    {
                        title: 'Global Tenants',
                        path: '/global_tenants',
                    },
                    {
                        title: 'New Purchase Tenants',
                        path: '/new_purchase_tenants',
                    },
                    {
                        title: 'Regularization Tenants',
                        path: '/regularization_tenants',
                    },
                    {
                        title: 'Transfer Tenants',
                        path: '/transfer_tenants',
                    },
                    {
                        title: 'Others Tenants',
                        path: '/others_tenants',
                    },
                    {
                        title: 'Zonal Tenants',
                        path: '/zonal_tenants',
                    },
                ],
            },
            {
                title: 'Tenants Update',
                path: '/#',
                subNav: [
                    {
                        title: 'Personal Information',
                        path: '/personal_information',
                    },
                    {
                        title: 'Contact Information',
                        path: '/contact_information',
                    },
                    {
                        title: 'Next Of Kin',
                        path: '/next_of_kin',
                    },
                    {
                        title: 'Contact Person/Reference',
                        path: '/contact_person_reference',
                    },
                ],
            },

        ]
    },
    {
        title: 'Operations Management',
        subNav: [
            {
                title: 'Approvals',
                path: '/#',
                subNav: [
                    {
                        title: 'Pending Approvals',
                        path: '/pending_approvals',
                    },
                    {
                        title: 'Approved Tenants',
                        path: '/approved_tenants',
                    },
                    {
                        title: 'Rejected Tenants',
                        path: '/rejected_tenants',
                    },
                ],
            },
            {
                title: 'Responses',
                path: '/#',
                subNav: [
                    {
                        title: 'Update Response',
                        path: '/update_response',
                    },
                    {
                        title: 'Activation Response',
                        path: '/activation_response',
                    },
                    {
                        title: 'Messages Response',
                        path: '/messages_response',
                    },
                ],
            },
        ]
    },
    {
        title: 'Accounts Management',
        path: '/#',
        subNav: [
            {
                title: 'Receive Payment',
                path: '/receipt_page',
            },

            {
                title: 'Refund Payment',
                path: '/refund_payment',
            },
            {
                title: 'Payments',
                path: '/#',
                subNav: [
                    {
                        title: 'Payment Transactions',
                        path: '/payment_transactions',
                    },
                    {
                        title: 'Payment Clearance',
                        path: '/payment_clearance',
                    },
                    {
                        title: 'Payment Types',
                        path: '/payment_types',
                    },
                    {
                        title: 'Yearly Payments',
                        path: '/yearly_payments',
                    },
                ],
            },
            {
                title: 'Ledgers',
                path: '/#',
                subNav: [
                    {
                        title: 'Ledger Accounts',
                        path: '/ledger_accounts',
                    },
                    {
                        title: 'Yearly Ledger',
                        path: '/yearly_ledger',
                    },
                ],
            },
            {
                title: 'Expenditure',
                path: '/#',
                subNav: [
                    {
                        title: 'Expenditure Items',
                        path: '/expenditure',
                    },
                ],
            },
            {
                title: 'Revenue',
                path: '/#',
                subNav: [
                    {
                        title: 'Revenue Items',
                        path: '/revenue',
                    },
                ],
            },
            {
                title: 'Accounts Report',
                path: '/accounts_report',
            },

        ]
    },
    {
        title: 'Legal Issues',
        subNav: [
            {
                title: 'Court Cases',
                path: '/court_cases',
            },
            {
                title: 'Pending Cases',
                path: '/pending_cases',
            },
            {
                title: 'Resolved Cases',
                path: '/resolved_cases',
            },
        ]
    },
    {
        title: 'General Reports',
        subNav: [
            {
                title: 'Operational Reports',
                path: '/operational_reports',
            },
        ]
    },
    {
        title: 'Statements',
        subNav: [
            {
                title: 'Tenant Detailed Statement',
                path: '/tenant_detailed_statement',
            },
            {
                title: 'Tenant Summary Statement',
                path: '/tenant_summary_statement',
            },
        ]
    },
    {
        title: 'System Admin',
        subNav: [
            {
                title: 'Users',
                subNav: [
                    {
                        title: 'Admin Users',
                        path: '/admin_users',
                    },
                ],
            },
            {
                title: 'Roles',
                path: '/roles',
            },
            {
                title: 'General Setup',
                path: '/general_setup',
            },
        ]
    },
    {
        title: 'Logout',
        path: '/logout',
    },

];

