import React, { useEffect, useRef, useState } from 'react'
import { useMutation } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast'
import {
    findTransactionIDInfo,
    captureMomoPayment,
} from '../../../../api/apiServices';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

const CaptureMomo = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const inputRef = useRef();

    useEffect(() => {
        inputRef.current.focus();
    }, [])

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/capture_momo";

    const [mobile_number, setMobileNumber] = useState("")
    const [country, setCountry] = useState("gh");

    const [amount, setAmount] = useState("")
    const [transaction_id, setTransactionId] = useState("")
    const [reference, setReference] = useState("")


    const handleTransactionID = (e) => {
        const transID = e.target.value;
        if (transID === "") {
            toast.error('Please enter the transaction ID', {
                duration: 5000,
                className: 'toast-message',
            });
            inputRef.current.focus();
            return false
        } else {
            getTransactionIDInfoMutation.mutate(transID);
        }
    }

    const getTransactionIDInfoMutation = useMutation(findTransactionIDInfo, {
        onSuccess: async (res) => {
            if (!res.status) {
                toast.error(res.message, {
                    duration: 5000,
                    className: 'toast-message',
                });
                inputRef.current.focus();
                return false
            } else {
                const mobile_number = res.data.mobile_number
                const amount = res.data.amount
                const reference = res.data.car_number
                setMobileNumber(mobile_number)
                setAmount(amount)
                setReference(reference)
                return true;
            }
        },
        onError: async (err) => {
            toast.error(err.message, {
                duration: 5000,
                className: 'toast-message'
            });
            inputRef.current.focus();
            return false
        }
    })


    const handleSubmit = (e) => {
        e.preventDefault();
        const paymentData = {
            transaction_id: transaction_id,
            mobile_number: mobile_number,
            amount: parseFloat(amount).toFixed(2),
            reference: reference? reference.toLocaleUpperCase():"",
        };
        captureMomoPaymentMutation.mutate(paymentData);
    }

    const captureMomoPaymentMutation = useMutation(captureMomoPayment, {
        onSuccess: async (res) => {
            if (res.success === false) {
                toast.error(res.message, {
                    duration: 5000,
                    className: 'toast-message'
                });
                inputRef.current.focus();
                return false
            }
            if (res.success === true) {
                toast.success(res.message, {
                    duration: 5000,
                    className: 'toast-message'
                });
            }
            setTransactionId("")
            setMobileNumber()
            setAmount("")
            setReference("")
            setCountry("gh");
            navigate(from, { replace: true });
        },
        onError: async (err) => {
            toast.error(err.message, {
                duration: 5000,
                className: 'toast-message',
            })
            inputRef.current.focus();
            return false
        }
    })

    const handleCancel = () => {
        navigate('/dashboard')
    }

    const captureForm =
        <div className="payment-container">
            <form onSubmit={handleSubmit}>
                <div className="form-group row">
                    <fieldset className="col-sm-6">
                        <div className='form-group row'>
                            <label htmlFor="transaction_id" className="col-sm-3">Transaction ID<span>&nbsp;&nbsp;*</span></label>
                            <div className="col-sm-9">
                                <input
                                    ref={inputRef}
                                    type="text"
                                    id='transaction_id'
                                    style={{
                                        height: "40px",
                                        textTransform: "uppercase",
                                    }}
                                    placeholder='Transaction ID'
                                    name='transaction_id'
                                    required
                                    value={transaction_id}
                                    onBlur={handleTransactionID}
                                    onChange={(e) => setTransactionId(e.target.value)}
                                    className="form-control" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="mobile_number" className="col-sm-3 col-form-label">Mobile Number<span>&nbsp;&nbsp;*</span></label>
                            <div className="col-sm-9">
                                <PhoneInput
                                    type="tel"
                                    id="mobile_number"
                                    inputStyle={{
                                        width: "100%",
                                        height: "40px"
                                    }}
                                    disabled={true}
                                    countryCodeEditable={false}
                                    country={country}
                                    enableSearch={true}
                                    value={mobile_number}
                                    name="mobile_number"
                                    onChange={(value, countryObj, e, formattedValue) =>
                                        setMobileNumber(value, countryObj, e, formattedValue)
                                    }
                                />
                               </div>
                        </div>
                        <div className='form-group row'>
                            <label htmlFor="reference" className="col-sm-3">Reference</label>
                            <div className="col-sm-9">
                                <input
                                    type="text"
                                    name='reference'
                                    id='reference'
                                    placeholder='Reference'
                                    style={{
                                        height: "40px",
                                        textTransform: "uppercase",
                                    }}
                                    disabled
                                    autoComplete='off'
                                    value={reference}
                                    onChange={(e) => setReference(e.target.value)}
                                    className="form-control" />
                            </div>
                        </div>
                        <div className='form-group row'>
                            <label htmlFor="amount" className="col-sm-3">Amount<span>&nbsp;&nbsp;*</span></label>
                            <div className="col-sm-9">
                                <input
                                    type="number"
                                    name='amount'
                                    id='amount'
                                    style={{
                                        height: "40px",
                                        textTransform: "uppercase",
                                    }}
                                    placeholder='Amount'
                                    required
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                    className="form-control" />
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div className="panel-footer">
                    <div className='form-group row'>
                        <div className="col-sm-1">
                            <button type="submit" className='btn btn-success btn-block'>Submit</button>
                        </div>&nbsp;&nbsp;&nbsp;&nbsp;
                        <div className="col-sm-1">
                            <button type="button" className='btn btn-danger btn-block' onClick={handleCancel}>Cancel</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>


    return (
        <>
            <section className="content-header">
                <h1><i className="fas fa-bank"></i> Momo Payments</h1>
                <Toaster toastOptions={{ position: "top-center" }} />
            </section>
            <section className="content">
                <div className="box">
                    <div className="box-body">
                        <div className="panel panel-default panel-br-4">
                            <div className="panel-heading">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h4 className="panel-title">
                                            <span>Capture Momo Payment</span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-body">
                                {captureForm}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CaptureMomo