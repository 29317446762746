import React from "react";
import { Outlet } from 'react-router-dom';
import Footer from '../../admin/footer/Footer';
// import AdminHeader from "../../admin/header/AdminHeader/AdminHeader";
import UserRoles from '../../../hooks/userRoles';
import Navigation from "../navigation/Navbar/Navigation";
import Sidebar from "../../admin/sidebar/Sidebar";

const Layout = () => {
  return (
    <div className="wrapper">
      <Navigation allowedRoles={UserRoles} /> 
      <Sidebar allowedRoles={UserRoles} />
      <div className="content-wrapper">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
