import useMember from './useMember';
import { driverRefreshToken } from '../api/apiServices'

const useMemRefreshToken = () => {
    const { setMem } = useMember();
 
    const refresh = async () => {
        const response = await driverRefreshToken();
        setMem(prev => {
            // console.log("Previous :", JSON.stringify(prev));
            // console.log("New Token :", response?.accessToken);
            return {
                ...prev,
                user: response?.user,
                pfNumber: response?.pfNumber,
                email: response?.email,
                accessToken: response?.accessToken,
                user_role: response?.user_role
            }
        });
        return response.accessToken;
    }
    return refresh;
};

export default useMemRefreshToken;
