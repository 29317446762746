import React, { useRef, useState, useEffect } from 'react';
import useMember from '../../../hooks/useMember';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useMutation } from 'react-query'
import {
	// checkPfNumber,
	mainLogin,
	// verifyUserEmail
} from '../../../api/apiServices';
import toast, { Toaster } from 'react-hot-toast'
import './MainLogin.css'

const MainLogin = () => {

	const { setMem, mempersist, setMemPersist } = useMember();

	const navigate = useNavigate();
	const location = useLocation();
	const from = location.state?.from?.pathname || "/member_dashboard";

	const userRef = useRef();

	const [car_number, setCarNumber] = useState("")
	const [driver_code, setDriverCode] = useState("")
	const [password, setPassword] = useState("")

	useEffect(() => {
		userRef.current.focus();
	}, [])

	const driverLoginMutation = useMutation(mainLogin, {
		onSuccess: async (res) => {
			if (res.status === false) {
				toast.error(res.message, {
					duration: 5000,
				})
			} else {
				const pfNumber = res?.pfNumber;
				const email = res?.email;
				const user = res?.user;
				const user_role = res?.user_role;
				const accessToken = res?.accessToken;

				setMem({ user, email, pfNumber, accessToken, user_role });
			}
		},
		onError: async (err) => {
			toast.error(err.message)
		}
	})

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!(car_number && driver_code && password)) return false;

		const loginData = {
			car_number: car_number,
			driver_code: driver_code,
			password: password
		}
		console.log(loginData)
		driverLoginMutation.mutate(loginData)
	}

	const toggleMemPersist = () => {
		setMemPersist(prev => !prev);
	}

	useEffect(() => {
		localStorage.setItem('mempersist', mempersist)
	}, [mempersist])

	const handleForgotPswd = () => {
		if (!car_number) {
			toast.error('Enter your Car Number', {
				duration: 5000,
				className: "toast-message",
			});
			userRef.current.focus();
			return false
		}
		// verifyUserEmail(state.pf_number_email).then(() => {
		// 	navigate('/verify_secret_code')
		// })
	}

	const mainForm = (
		<>
			<div className="login-wrapper">		
				<div className="logo-image">
					<img src='/images/logo/crysec.jpg' alt='crysec' />
				</div>
				<form onSubmit={handleSubmit}>
					<div className="form-group row">
						<div className="col-sm-12">
							<input
								ref={userRef}
								id="car_number"
								type="text"
								autoComplete="off"
								style={{
									height: "40px",
									textTransform: "uppercase",
								}}
								name="car_number"
								placeholder="Car Number"
								onChange={(e) => setCarNumber(e.target.value)}
								value={car_number}
								required
								className="form-control"
							/>
						</div>
					</div>
					<div className="form-group row">
						<div className="col-sm-12">
							<input
								ref={userRef}
								id="driver_code"
								type="text"
								autoComplete="off"
								style={{
									height: "40px",
									textTransform: "uppercase",
								}}
								name="driver_code"
								placeholder="Driver Code"
								onChange={(e) => setDriverCode(e.target.value)}
								value={driver_code}
								required
								className="form-control"
							/>
						</div>
					</div>
					<div className="form-group row">
						<div className="col-sm-12">
							<input
								type="password"
								name="password"
								placeholder="Password"
								style={{
									height: "40px",
									textTransform: "uppercase",
								}}
								autoComplete="off"
								required
								onChange={(e) => setPassword(e.target.value)}
								value={password}
								className="form-control" />
						</div>
					</div>
					<input type="submit" value="Login" className=" btn btn-block btn-dark" />
					<div className="forgot-pswd">
						<Link onClick={handleForgotPswd}>Forgot password?</Link>
					</div>
					<div className='persistenceCheck'>
						<input
							type='checkbox'
							id='memPersist'
							onChange={toggleMemPersist}
							checked={mempersist}
						/>
						<label htmlFor='memPersist'>Remember me</label>
					</div>
				</form>
		</div>
		</>
	)

	return (
		<>
			<div className="main-screen-container">
				{mainForm}
			</div>
		</>
	)
}

export default MainLogin