import React, { useState } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import {
    getRegFeePayments,
    searchRegFeePayment
} from "../../../../../api/apiServices";
import Pagination from '../../../../../services/Pagination';
import toast, { Toaster } from 'react-hot-toast'
import addCommas from "../../../../../services/addCommas";

const RegistrationFees = (props) => {

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/payment_transactions";

    const [search, setSearch] = useState(false);
    const [regFee, setRegFee] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [postPerPage, setPostPerPage] = useState(20);

    const [driver_code, setDriverCode] = useState("");
    const [mobile_number, setMobileNumber] = useState("");
    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");

    const queryClient = useQueryClient();

    const {
        isLoading,
        isError,
        error,
        data: regFees
    } = useQuery('regFees', getRegFeePayments);

    let total_regFees = 0
    if (regFees) {
        for (var i = 0; i < regFees.length; i++) {
            total_regFees += parseFloat(regFees[i].amount_paid)
        }
    }

    const handleSearch = async (e) => {
        e.preventDefault();
        const searchData = {
            driver_code: driver_code,
            mobile_number: mobile_number,
            first_name: first_name,
            last_name: last_name,
        }
        try {
            await searchRegFeePayment(searchData).then((res) => {
                if (res.length > 0) {
                    setRegFee(res)
                    setDriverCode("");
                    setMobileNumber("");
                    setFirstName("");
                    setLastName("");
                    setSearch(true)
                } else {
                    toast.info("No Payment found for member")
                    setSearch(false)
                }
            }).catch((err) => {
                toast.error("Provide at least one search parameter ")
                return false
            })
        } catch (err) {
            toast.error(err)
        }
    }
    const handleCancel = () => {
        setDriverCode("");
        setMobileNumber("");
        setFirstName("");
        setLastName("");
        setSearch(false);
        navigate(from, { replace: true });
    }

    let currentPosts = []
    const indexOfLastPage = currentPage * postPerPage;
    const indexOfFirstPage = indexOfLastPage - postPerPage;
    if (!search) {
        currentPosts = regFees?.slice(indexOfFirstPage, indexOfLastPage);
    } else {
        currentPosts = regFee?.slice(indexOfFirstPage, indexOfLastPage);
    }

    let searchContent = (
        <form onSubmit={handleSearch} >
            <div className="form-group row">
                <div className="col-sm-2">
                    <input
                        className="form-control"
                        type="text"
                        placeholder="Driver Code"
                        style={{ textTransform: "uppercase" }}
                        onChange={(e) => setDriverCode(e.target.value)}
                        value={driver_code}
                    />
                </div>
                <div className="col-sm-2">
                    <input
                        className="form-control"
                        type="text"
                        // style={{ textTransform: "uppercase" }}
                        placeholder="Mobile Number"
                        onChange={(e) => setMobileNumber(e.target.value)}
                        value={mobile_number}
                    />
                </div>
                <div className="col-sm-3">
                    <input
                        className="form-control"
                        type="text"
                        placeholder="FirstName"
                        style={{ textTransform: "uppercase" }}
                        onChange={(e) => setFirstName(e.target.value)}
                        value={first_name}
                    />
                </div>
                <div className="col-sm-3">
                    <input
                        className="form-control"
                        type="text"
                        placeholder="LastName"
                        style={{ textTransform: "uppercase" }}
                        onChange={(e) => setLastName(e.target.value)}
                        value={last_name}
                    />
                </div>
                <div className="col-sm-2">
                    <button
                        type="submit"
                        className="btn btn-success btn-block "
                    > <i className="fa fa-search"></i> Search
                    </button>
                </div>
            </div>
        </form>
    )

    let content
    if (isLoading) {
        content = <p>Loading Registration Fees...</p>
    } else if (isError) {
        content = <p>{error.message}</p>
    } else {
        content =
            <div className="table-responsive lg table-wrapper">
                <table className="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th scope="col" style={{ textAlign: 'center' }} >#</th>
                            <th scope="col" style={{ whiteSpace: 'nowrap' }}>Driver Code</th>
                            <th scope="col" style={{ whiteSpace: 'nowrap' }}>Car Number</th>
                            <th scope="col" style={{ whiteSpace: 'normal' }}>Full Name</th>
                            <th scope="col" style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Mobile Number</th>
                            <th scope="col" style={{ whiteSpace: 'normal', textAlign: "center" }}>Payment Date</th>
                            <th scope="col" style={{ whiteSpace: 'normal', textAlign: "center" }}>Transaction ID</th>
                            <th scope="col" style={{ whiteSpace: 'normal', textAlign: "right" }}>Amount Paid</th>
                            <th scope="col" style={{ textAlign: "center" }}>ACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentPosts.map((paymt, index) =>

                            <tr key={paymt.id}>
                                <td style={{ textAlign: 'center' }}>{postPerPage * (currentPage - 1) + index + 1}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{paymt?.driver_code}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{paymt?.car_number}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{paymt?.drivers?.first_name + " " + paymt?.drivers?.last_name}</td>
                                <td style={{ textAlign: 'center' }}>{paymt?.mobile_number}</td>
                                <td style={{ textAlign: 'center' }}>{paymt?.transaction_date}</td>
                                <td style={{ textAlign: 'center' }}>{paymt?.transaction_id}</td>
                                <td style={{ textAlign: 'right' }}>{addCommas(paymt?.amount_paid)}</td>
                                <td style={{ whiteSpace: 'nowrap', textAlign: "center" }}>
                                    <div style={{ display: 'inline-block' }}>
                                        <Link to={`/view_payment/${paymt?.driver_code}`} className="small"><i className='fa fa-eye'></i> view</Link>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody >
                    <tbody>
                        <tr>
                            <td colSpan="9"></td>
                        </tr>
                        <tr>
                            <td colSpan="7" className="border-bottom" style={{ width: "55%", textAlign: "left", fontSize: "16px", fontWeight: "700", color: "darkred" }}>
                                TOTAL REGISTRATION FEES
                            </td>
                            <td className="border-bottom" style={{ width: "40%", textAlign: "right", fontSize: "16px", fontWeight: "700", color: "darkred" }}>
                                {total_regFees.toLocaleString(undefined, { minimumFractionDigits: 2 })} </td>
                            <td style={{ whiteSpace: 'nowrap', textAlign: "center" }}>
                                <div style={{ display: 'inline-block' }}>
                                    <Link to={`/view_payment`} className="small"><i className='fa fa-eye'></i> view</Link>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="9" style={{ width: "95%", borderBottom: "5px" }}></td>
                        </tr>
                    </tbody>
                </table >
            </div>
    }

    return (
        <>
            <section className="content-header">
                <Toaster toastOptions={{ position: "top-center" }} />
                <h1>Registration Fees</h1>
            </section>
            <section className="content">
                <div className="box">
                    <div className="box-body">
                        <div className="panel panel-default panel-br-4">
                            <div className="panel-heading">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h4 className="panel-title">
                                            {searchContent}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-body">
                                <div className="col-sm-4">
                                    <Link className="btn btn-success btn-block" to="/regFees_by_period"><i className='fa fa-eye'></i>&nbsp; &nbsp;
                                        Period Payment</Link>&nbsp; &nbsp;
                                </div>
                                <div className="col-sm-4">
                                    <Link className="btn btn-success btn-block" to="/regFees_by_month"><i className='fa fa-eye'></i>&nbsp; &nbsp;Monthly Payment</Link>&nbsp; &nbsp;
                                </div>
                                <div className="col-sm-4">
                                    <Link className="btn btn-success btn-block" to="/regFees_by_mode"><i className='fa fa-eye'></i>&nbsp; &nbsp;Payments By Mode</Link>
                                </div>
                                <br />
                            </div>
                            <div className="panel-body">
                                {content}
                            </div>
                            <div className="panel-footer">
                                {regFees && !search ? (
                                    <Pagination
                                        data={regFees}
                                        postPerPage={postPerPage}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        setPostPerPage={setPostPerPage}
                                    />
                                ) : (
                                    <div className="search-group">
                                        <button
                                            type="submit"
                                            className="btn btn-danger "
                                            onClick={handleCancel}
                                        > <i className="fa fa-search"></i> Cancel</button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="box-footer"></div>
                </div>
            </section >
        </>
    )
}

export default RegistrationFees;


