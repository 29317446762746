import React from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import {
    getDriverById
} from "../../../../api/apiServices";
import { useQuery } from 'react-query';
import DriverView from './DriverView'
import DriverCardLeft from './DriverCardLeft'
import DriverCardRight from './DriverCardRight'

const DriverDisplay = () => {
    const params = useParams();
    const id = parseInt(params.id)

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/global_drivers";

    const {
        isLoading,
        error,
        data: driver
    } = useQuery(['driver', id], () => getDriverById(id));

    let fullname = driver?.first_name + " " + driver?.middle_name + " " + driver?.last_name

    let content
    if (isLoading) {
        content = <p>Loading Driver Details...</p>
    } else if (error) {
        content = <p>{error.message}</p>
    } else {
        content = (
            <>
            </>
        )
    }
    const handleCancel = () => {
        navigate(from, { replace: true });
    }

    return (
        <>
            <section className="content-header">
                <h1>Driver Details - { fullname}</h1>
            </section>
            <section className="content">
                <div className="box">
                    <div className="box-body">
                        <DriverView>
                            <DriverCardLeft
                                title={fullname}
                                imageUrl={`/images/drivers/${driver?.photo}`}
                                code={driver?.driver_code}
                            />
                            <DriverCardRight
                                car_number={driver?.car_number}
                               
                            />
                        </DriverView>

                    </div>
                    <div className="box-footer">
                        <button
                            type="button"
                            className="btn btn-danger "
                            onClick={handleCancel}
                        > <i className="fa fa-search"></i> Cancel</button>

                    </div>
                </div>
            </section>
        </>
    )
}

export default DriverDisplay