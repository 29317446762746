import { Outlet } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import useMemRefreshToken from "../../../hooks/useMemRefreshToken";
import useMember from "../../../hooks/useMember";

const MemPersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const refreshMem = useMemRefreshToken();
    const { mem, mempersist } = useMember();

    useEffect(() => {
        let isMounted = true;
        const verifyMemRefreshToken = async () => {
            try {
                await refreshMem();
            } catch (err) {
                console.error(err);
            }
            finally {
                isMounted && setIsLoading(false);
            }
        }

        !mem?.accessToken ? verifyMemRefreshToken() : setIsLoading(false);

        return () => isMounted = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        // console.log(`isLoading: ${isLoading}`);
        // console.log(`aT: ${JSON.stringify(mem?.accessToken)}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading])

    return (
        <>
            {!mempersist
                ? <Outlet />
                : isLoading
                    ? <p>Loading...</p>
                    : <Outlet />
            }
        </>
    )
}

export default MemPersistLogin