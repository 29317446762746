import { createContext, useState } from "react";

const MemberContext = createContext({});

export const MemberProvider = ({ children }) => {
    const [mem, setMem] = useState({});
    const [mempersist, setMemPersist] = useState(JSON.parse(localStorage.getItem("mempersist")) || false)

    return (
        <MemberContext.Provider value={{ mem, setMem, mempersist, setMemPersist }}>
            {children}
        </MemberContext.Provider>
    )
}

export default MemberContext;