import './MainFooter.css';

const MainFooter = () => {
    // const today = new Date();
    return (
        <><section className='footerContact'>
            <div className='container'>
                <div className='flex'>
                    <h1>Get Your Vehicle & Yourself Secured</h1>
                </div>
            </div>
        </section><footer className='footer'>

                <div className="top-image">
                    <img src='/images/logo/crysec.png' alt='' />
                    <h2>Do You Need Help With Your Security On The Road?</h2>
                    <p>Get 24/7 security for your vehicle and yourself with our Response Team</p>
                </div>

                <div className='top'>
                    <div className='item'>
                        <h1>OFFICE LOCATION </h1>
                        <p>Nii Odai Ntow</p>
                        <p>Land Secretariat,</p>
                        <p>Kwabenya, Accra</p>

                    </div>
                    <div className='item'>
                        <h1>GPS ADDRESS</h1>
                        <p>GE-181-9449</p>
                    </div>
                    <div className='item'>
                        <h1>EMAIL</h1>
                        <a href="mailto:info@crysecghana.com">info@crysecghana.com</a><br />
                    </div>
                    <div className='item'>
                        <h1>MOBILE NUMBERS</h1>
                        <a href="tel:233538934447">233538934447</a><br />
                        <a href="tel:233538933448">233538933448</a><br />
                        <a href="tel:233538950900">233538950900</a>
                    </div>
                    <div className='item'>
                        <h1>FOLLOW US ON</h1>
                        <a target="_blank" rel='noreferrer' href='https://facebbok.com/crysecghana'>
                            <i className='fab fa-facebook square'></i>
                        </a>
                        <a target="_blank" rel='noreferrer' href='https://instagram.com/crysecghana'>
                            <i className='fa-brands fa-instagram-square'></i>
                        </a>
                        <a target="_blank" rel='noreferrer' href='https://twitter.com/crysecghana'>
                            <i className='fa-brands fa-twitter-square'></i>
                        </a>
                    </div>
                </div>
                <div className='bottom'>
                    <div className='item'>
                        <h1>SECURITY EQUIPMENT</h1>
                        <a href="#">Camera</a>
                        <a href="#">GPS Tracker</a>
                        <a href="#">Panic Buttons</a>
                        <a href="#">Pepper Spray</a>
                    </div>
                    <div className='item'>
                        <h1>24/7 RESPONSE TEAM</h1>
                        <a href="">Highly trained professionals ready to assist drivers in distress.</a>
                        <a href="">Seamless integration with law enforcement for swift emergency response.</a>
                    </div>
                    <div className='item'>
                        <h1>TARGET MARKET</h1>
                        <a href="">Private Car Owners</a>
                        <a href="">Taxis</a>
                        <a href="">Ride-Sharing Services</a>
                    </div>
                </div>
            </footer>
            <div className='legal'>
                Copyright &copy; 2024 <strong style={{ fontSize: "18px", color: "var(--white)" }}>CRYSEC SERVICES LTD</strong>. All rights reserved.
            </div>
        </>
    )
}

export default MainFooter
