import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthProvider';
import { MemberProvider } from './context/MemberProvider';
import { GeneralSearchProvider } from './context/GeneralSearchContext'
import { SpecialSearchProvider } from './context/SpecialSearchContext'
import { QueryClientProvider, QueryClient } from 'react-query';
import App from "./App"


import { disableReactDevTools } from '@fvilers/disable-react-devtools';
if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <GeneralSearchProvider>
        <SpecialSearchProvider>
          <AuthProvider>
            <MemberProvider>
              <Routes>
                <Route path="/*" element={<App />} />
              </Routes>
          </MemberProvider>
          </AuthProvider>
        </SpecialSearchProvider>
      </GeneralSearchProvider>
    </BrowserRouter>
    {/* <ReactQueryDevtools initialIsOpen={false} /> */}
  </QueryClientProvider>
)
