import React from "react"
import img from "../images/services.jpg"
import Terms from "../../common/Terms"
import "../home/featured/Featured.css"

const TermsConditions = () => {
  return (
    <>
      <section className='services mb'>
        <Terms title='TERMS & CONDITIONS' name='Terms and Conditions for Crysec Services Ltd' cover={img} />
        <div class="terms-heading">
          <h1>1. INTRODUCTION</h1>
          <p>These Terms and Conditions ("Agreement") governs the use of security services provided by CRYSEC SERVICES LTD, a multinational company offering 24-hour security, rapid response, tracking and monitoring, and video recording services for commercial and private drivers</p>
          <p>By engaging with our services, you agree to comply with the terms outlined in this Agreement. If you do not agree with these terms, you should not use our services.</p>
        </div>
        <hr />
        <div class="terms-heading">
          <h1>2. SCOPE OF SERVICE</h1>
          <ul>
            <li>
              <p>
                <strong>24-Hour Security :</strong> Our security services operate 24/7 to ensure the safety and security of our clients.
              </p>
            </li>
            <li>
              <p>
                <strong>Rapid Response Team :</strong> We have a team ready to respond quickly to incidents, emergencies, or security threats.
              </p>
            </li>
            <li>
              <p>
                <strong>Tracking and Monitoring :</strong> We use GPS and other tracking  technologies to monitor the location and status of vehicles in real-time.
              </p>
            </li>
            <li>
              <p>
                <strong>Video Recordings :</strong> Our clientele vehicles and security personnel are equipped with video recording equipments for evidence collection and safety monitoring.
              </p>
            </li>
          </ul>
        </div>
        <hr />
        <div class="terms-heading">
          <h1>3. CLIENT OBLIGATIONS</h1>
          <ul>
            <li>
              <p>
                <strong>Compliance with Laws :</strong> Clients must comply with all applicable laws and regulations while using our services.
              </p>
            </li>
            <li>
              <p>
                <strong>Use of Services :</strong> Clients must use our services for their intended purposes and not engage in any illegal or unauthorized activities.
              </p>
            </li>
            <li>
              <p>
                <strong>Consent to Tracking :</strong> By using our services, clients consent to being tracked and monitored for security purposes.
              </p>
            </li>
            <li>
              <p>
                <strong>Consent to Video Recording :</strong> Clients consent to video recording and understand that these recordings may be used as evidence in case of attacks, robberies, or other incidents.
              </p>
            </li>
          </ul>
        </div>
        <hr />
        <div class="terms-heading">
          <h1>4. PRIVACY AND DATA PROTECTION</h1>
          <ul>
            <li>
              <p>
                <strong>Data Collection :</strong> We collect and process personal data, including location and video recordings, in accordance with applicable data protection laws.
              </p>
            </li>
            <li>
              <p>
                <strong>Use of Data :</strong> Personal data is used solely for providing security services and shall not be shared with third parties, except as required by law or with the client's consent.
              </p>
            </li>
            <li>
              <p>
                <strong>Data Security :</strong> We implement appropriate measures to protect personal data against unauthorized access, disclosure, or misuse.
              </p>
            </li>
            <li>
              <p>
                <strong>Data Retention :</strong> Video recordings and other data are retained for a limited time, as required for security purposes or legal compliance.
              </p>
            </li>
          </ul>
        </div>
        <hr />
        <div class="terms-heading">
          <h1>5. LIABILITY AND INDEMNITY</h1>
          <ul>
            <li>
              <p>
                <strong>Limitation of Liability :</strong> CRYSEC SERVICES LTD not liable for any direct, indirect, incidental, or consequential damages arising from the use of our services, except in cases of gross negligence or intentional misconduct.
              </p>
            </li>
            <li>
              <p>
                <strong>Indemnity :</strong> Clients agree to indemnify and hold CRYSEC SERVICES LTD harmless from any claims, damages, or expenses arising from their use of our services.
              </p>
            </li>
            <li>
              <p>
                <strong>Data Security :</strong> We implement appropriate measures to protect personal data against unauthorized access, disclosure, or misuse.
              </p>
            </li>
            <li>
              <p>
                <strong>Data Retention :</strong> Video recordings and other data are retained for a limited time, as required for security purposes or legal compliance.
              </p>
            </li>
          </ul>
        </div>
        <hr />
        <div class="terms-heading">
          <h1>6. TERMINATION AND SUSPENSION</h1>
          <ul>
            <li>
              <p>
                <strong>Termination :</strong> CRYSEC SERVICES LTD reserves the right to terminate this Agreement or suspend services at any time without notice for breach of terms or other valid reasons.
              </p>
            </li>
            <li>
              <p>
                <strong>Client Termination :</strong> Clients may terminate this Agreement by providing written notice to CRYSEC SERVICES LTD.
              </p>
            </li>
          </ul>
        </div>
        <hr />
        <div class="terms-heading">
          <h1>7. GOVERNING LAW AND JURISDICTION</h1>
          <p>This Agreement is governed by the laws of Republic of Ghana, and any disputes arising from this agreement shall be subject to the exclusive jurisdiction of the courts in Ghana. </p>
        </div>
        <hr />
        <div class="terms-heading">
          <h1>8. AMENDMENTS</h1>
          <p>CRYSEC SERVICES LTD reserves the right to amend these Terms and Conditions. Clients shall be notified of any significant changes, and continued use of our services constitutes acceptance of the amended terms. </p>
        </div>
        <hr />
        <div class="terms-heading">
          <h1>9. CONTACT INFORMATION</h1>
          <p>For questions or concerns regarding these Terms and Conditions, please contact CRYSEC SERVICES LTD on the following: </p>
          <ul className="contact-info">
            <li>
              <h3>Mobile Numbers</h3>
              <ul>
                <li>
                  <p><strong>+233538950900</strong></p>
                  <p><strong>+233538934447</strong></p>
                  <p><strong>+233538933448</strong></p>
                </li>
              </ul>
            </li>
            <li>
              <h3>Email Address</h3>
              <ul>
                <li>
                  <p><strong>info@crysecghana.com</strong></p>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <hr />
      </section>
    </>
  )
}

export default TermsConditions
