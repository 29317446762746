import { useEffect } from "react";
import useRegistrationContext from "../../../hooks/useRegistrationContext"
import Checkbox from "../../common/checkbox/Checkbox"
import "react-phone-input-2/lib/bootstrap.css";
import { Link } from "react-router-dom";

const PaymentInfo = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const { handleAgreement, } = useRegistrationContext()

    const content = (
        <>
            <div className="payment-info">
                <h3>PAYMENT INFORMATION</h3>
                <p>To complete the registration process</p>
                <p>Kindly pay the Registration Fee of <strong>GHS200.00</strong> </p>
                <p>to any of the following Momo Merchant Account Numbers:</p>
                <p><strong>765682</strong></p>
                <p><strong>256280</strong></p>
                <br />
                <p>You can also pay the Registration Fee</p>
                <p>to any of the following Mobile Account Numbers:</p>
                <p><strong>233530461314</strong></p>
                <p><strong>233530461980</strong></p>

                <h3>Thank you</h3>
            </div>
        </>
    )

    return content
}
export default PaymentInfo