import React from "react"
import { registrationInfo } from "../../data/Data"

const InformationCard = () => {
  return (
    <>
      <div className='content grid5 mtop'>
        {registrationInfo.map((items, index) => (
          <div className='box' key={index}>
            <img src={items.cover} alt='' />
            <h4>{items.name}</h4>
            <h3>{items.info}</h3>
            <p>{items.total}</p>
          </div>
        ))}
      </div>
    </>
  )
}

export default InformationCard
