import React, { useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
	getPaymentTypeById, updatePaymentType
} from "../../../../../api/apiServices";
import toast, { Toaster } from 'react-hot-toast'

const EditPaymentType = () => {
	const params = useParams();
	const typeId = parseInt(params.id)

	const navigate = useNavigate();
	const location = useLocation();
	const from = location.state?.from?.pathname || "/payment_types";

	
	const [type_code, setTypeCode] = useState("");
	const [description, setDescription] = useState("");
	
	const queryClient = useQueryClient();

	const {
		data: type,
		isLoading,
		isError,
		error,
	} = useQuery(["type", typeId], () => getPaymentTypeById(typeId));

	const updatePaymentTypeMutation = useMutation(updatePaymentType, {
		onSuccess: async (res) => {
			// Invalidate cached data
			toast.success(res.message, {
				duration: 10000,
			})
			setDescription("")
			queryClient.invalidateQueries("payment_types")
			navigate(-1);
		},
		onError: async (err) => {
			toast.error(err.message, {
				duration: 5000,
			})
		}
	})


	const handleSubmit = (e) => {
		e.preventDefault()

		const dataValues = {
			id: typeId,
			type_code: type_code.toLocaleUpperCase(),
			description: description.toLocaleUpperCase(),
		}
		updatePaymentTypeMutation.mutate(dataValues)
	}
	const handleCancel = () => {
		navigate(from, { replace: true });
	}

	let content
	if (isLoading) {
		content = <tr><td>Loading Payment Type...</td></tr>
	} else if (isError) {
		content = <tr><td>{error.message}</td></tr>
	} else {
		content = (
			<>
				<div className="panel panel-success panel-br-4">
					<div className="panel-heading">
						<div className="row">
							<div className="col-sm-6">
								<h4 className="panel-title">
									<i className="fa fa-file-text" aria-hidden="true"></i>
									<span> Update Payment Type</span>
								</h4>
							</div>
						</div>
					</div>
					<div className="panel-body">
						<form onSubmit={handleSubmit}>
							<div className="form-group row">
								<label htmlFor="type_code" className="col-sm-2 col-form-label">
									Payment Code
								</label>
								<div className="col-sm-6">
									<input
										type="text"
										id="type_code"
										autoComplete="off"
										style={{ textTransform: "uppercase" }}
										defaultValue={type.type_code}
										onChange={(e) => setTypeCode(e.target.value)}
										disabled
										name="type_code"
										className="form-control"
									/>
								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="description" className="col-sm-2 col-form-label">
									Description
								</label>
								<div className="col-sm-6">
									<input
										type="text"
										id="description"
										placeholder="Description"
										autoComplete="off"
										defaultValue={type.description}
										onChange={(e) => setDescription(e.target.value)}
										required
										style={{ textTransform: "uppercase" }}
										name="description"
										className="form-control"
									/>
								</div>
							</div>
							<div className="panel-footer">
								<div className="form-group row">
									<div className="col-sm-1">
										<button type="submit" className="btn btn-danger" onClick={handleCancel}>
											Cancel
										</button>
									</div>
									<div className="col-sm-1">
										<button type="submit" className="btn btn-success">
											Update
										</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</>
		)
	}

	return (
		<>
			<section className="content-header">
				<Toaster toastOptions={{ position: "top-center" }} />
				<h1>Edit Payment Type</h1>
			</section>
			<section className="content">
				<div className="box">
					<div className="box-body">
						<div className="panel panel-default panel-br-4">
							<div className="panel-heading">
								<div className="row">
									<div className="col-sm-12">
										<h4 className="panel-title">
										</h4>
									</div>
								</div>
							</div>
							<div className="panel-body">
								{content}
							</div>
						</div>
					</div>
					<div className="box-footer"></div>
				</div>
			</section>
		</>
	)
}

export default EditPaymentType