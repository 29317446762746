import { motion } from "framer-motion";
import Heading from "../../common/Heading";

function FlyerText() {
    return (
        <motion.h1
            animate={{ x: [0, 150, 50], opacity: 1, scale: 1 }}
            className="flyer-item"
            transition={{
                duration: 5,
                delay: 0.3,
                ease: [0.5, 0.71, 1, 1.5],
            }}
            initial={{ opacity: 0, scale: 0.5, color: 'blue' }}
            whileHover={{ scale: 1.2 }}
        >
            <div className="container">
                <Heading title='REGISTRATION INFORMATION'
                    subtitle='All you need to know to register with us.' />

            </div>
        </motion.h1>
    );
}
export default FlyerText;