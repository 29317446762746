import React from "react";

const CreatorLink = (props) => {
    return (
        <div className='creator-link'>
            <a href='https://aunsh.com' target={"_blank"} rel='noreferrer nofollow'>
                a.
            </a>
        </div>
    );
};

CreatorLink.propTypes = {};

export default CreatorLink;
