import useRegistrationContext from "../../../hooks/useRegistrationContext"
import PersonalInformation from "./PersonalInformation"
import ContactInformation from "./ContactInformation"
import NextOfKin from "./NextOfKin"
import ReferencePerson from "./ReferencePerson"
import TermsConditions from "./TermsConditions"
import PaymentInfo from "./PaymentInfo"

const FormInputs = () => {

    const { page } = useRegistrationContext()

    const display = {
        0: <PersonalInformation />,
        1: <ContactInformation />,
        2: <NextOfKin />,
        3: <ReferencePerson />,
        4: <TermsConditions />,
        5: <PaymentInfo />,
    }

    const content = (
        <div className="form-inputs flex-col">
            {display[page]}
        </div>
    )

    return content
}
export default FormInputs