import React, { useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { addPaymentType } from "../../../../../api/apiServices";
import toast, { Toaster } from 'react-hot-toast'

const AddPaymentType = () => {

	const navigate = useNavigate();
	const location = useLocation();
	const from = location.state?.from?.pathname || "/payment_types";


	const [type_code, setTypeCode] = useState("");
	const [description, setDescription] = useState("");
	const queryClient = useQueryClient();


	const addPaymentTypeMutation = useMutation(addPaymentType, {
		onSuccess: async (res) => {
			// Invalidate cached data
			queryClient.invalidateQueries("payment_types")
			toast.success(res.message, {
				duration: 5000,
			})
			setDescription("")
			navigate(from, { replace: true });
		},
		onError: async (err) => {
			toast.error(err.message, {
				duration: 5000,
			})
		}
	})

	const handleDescription = (e) => {
		const type = e.target.value
		const typeValue = type.replaceAll(" ", "_").toLocaleUpperCase()
		setDescription(type)
		if (typeValue === "SALES_&_REGULARIZATION") setTypeCode("CC1000")
		if (typeValue === "GROUND_RENT") setTypeCode("CC2000")
		if (typeValue === "CUSTOMARY_DRINK") setTypeCode("CC3000")
		if (typeValue === "DIGGING_FEE") setTypeCode("CC4000")
		if (typeValue === "FORMS") setTypeCode("CC5000")
		if (typeValue === "SITE_PLAN") setTypeCode("CC6000")
	}

	const handleSubmit = (e) => {
		e.preventDefault()

		const dataValues = {
			type_code: type_code.toLocaleUpperCase(),
			description: description.toLocaleUpperCase(),
		}
		addPaymentTypeMutation.mutate(dataValues)
	}

	const handleCancel = () => {
		navigate(from, { replace: true });
	}

	const content = (
		<>
			<div className="panel panel-default panel-br-4">
				<div className="panel-heading">
					<div className="row">
						<div className="col-sm-9">
							<h4 className="panel-title">
								<span> SETUP - Payment Types</span>
							</h4>
						</div>
					</div>
				</div>
				<form onSubmit={handleSubmit}>
					<div className="panel-body">
						<div className="form-group row">
							<label htmlFor="description" className="col-sm-2 col-form-label">
								Payment Type
							</label>
							<div className="col-sm-6">
								<input
									type="text"
									id="description"
									placeholder="Payment type"
									autoComplete="off"
									style={{ textTransform: "uppercase" }}
									value={description}
									onChange={handleDescription}
									required
									name="type_code"
									className="form-control"
								/>
							</div>
						</div>
						<div className="panel-footer">
							<div className="form-group row">
								<div className="col-sm-1">
									<button type="submit" className="btn btn-danger" onClick={handleCancel}>
										Cancel
									</button>
								</div>
								<div className="col-sm-1">
									<button type="submit" className="btn btn-success">
										Submit
									</button>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	)

	return (
		<>
			<section className="content-header">
				<Toaster toastOptions={{ position: "top-center" }} />
				<h1>Payment Types</h1>
			</section>
			<section className="content">
				<div className="box">
					<div className="box-body">
						<div className="panel panel-default panel-br-4">
							<div className="panel-heading">
								<div className="row">
									<div className="col-sm-12">
										<h4 className="panel-title">
										</h4>
									</div>
								</div>
							</div>
							<div className="panel-body">
								{content}
							</div>
						</div>
					</div>
					<div className="box-footer"></div>
				</div>
			</section>
		</>

	)
}

export default AddPaymentType