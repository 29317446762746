import { Outlet} from 'react-router-dom';
import React, { useState, useEffect } from "react";
import useRefreshToken from "../../../hooks/useRefreshToken";
import useAuth from "../../../hooks/useAuth";

const PersistLogin = () => {
    const [ isLoading, setIsLoading ] = useState(true);
    const refresh = useRefreshToken();
    const { auth, persist } = useAuth();

    useEffect(() => {
        let isMounted = true;
        const verifyRefreshToken = async () => {
            try {
                await refresh();
            } catch (err) {
                console.error(err);
            }
            finally {
                isMounted && setIsLoading(false);
            }
        }
      
        !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);
        
        return () => isMounted = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        // console.log(`isLoading: ${isLoading}`);
        // console.log(`aT: ${JSON.stringify(auth?.accessToken)}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading])

    return (
        <>
            {!persist
                ?   <Outlet />
                :   isLoading
                        ? <p>Loading...</p>
                        : <Outlet />
            }
        </>
    )
}

export default PersistLogin