const UserRoles = {
    SuperAdmin: 1000,
    SystemAdmin: 2000,
    SuperUser: 3000,
    Operations: 4000,
    Accounts: 5000,
    Legal: 6000,
    SystemUser: 7000,
    Tenant: 9999,
}

export default UserRoles