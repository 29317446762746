import axios from './axios';
const delay = () => new Promise((res) => setTimeout(() => res(), 3000));

// AUTH API
const authUrlEndPoint = '/auth';

export async function adminLogin(data) {
    const response = await axios.post(`${authUrlEndPoint}/login`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

export async function mainLogin(data) {
    const response = await axios.post(`${authUrlEndPoint}/main_login`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

export async function adminLogout() {
    await axios.get(`${authUrlEndPoint}/logout`,
        {
            withCredentials: true
        }
    );
    return
}
export async function driverLogout() {
    await axios.get(`${authUrlEndPoint}/main_logout`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return
}

export async function checkAdminUsername(username) {
    const response = await axios.post(`${authUrlEndPoint}/check_username`, { username: username },
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    )
    return response.data;
}

export async function setAdminPassword(data) {
    const response = await axios.post(`${authUrlEndPoint}/set_admin_password`, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}

export async function resetAccount(data) {
    const response = await axios.patch(`${authUrlEndPoint}/reset_admin_account/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

export async function resetMemberAccount(data) {
    const response = await axios.patch(`${authUrlEndPoint}/reset_driver_account/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

// REFRESH TOKEN
const ADMIN_REFRESH_TOKEN = '/token/admin_refresh_token';
const MEMBER_REFRESH_TOKEN = '/token/tenant_refresh_token';

export async function adminRefreshToken() {
    const response = await axios.get(ADMIN_REFRESH_TOKEN, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}
export async function driverRefreshToken() {
    const response = await axios.get(MEMBER_REFRESH_TOKEN, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}

// SYSTEM USERS  API
const usersUrlEndpoint = '/users'

export async function getAllUsers() {
    await delay()
    const response = await axios.get(`${usersUrlEndpoint}`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}

export async function addUser(data) {
    const response = await axios.post(`${usersUrlEndpoint}`, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}

export async function updateUser(data) {
    const response = await axios.patch(`${usersUrlEndpoint}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getUserById(id) {

    const response = await axios.get(`${usersUrlEndpoint}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

export async function deleteUser(id) {

    const response = await axios.delete(`${usersUrlEndpoint}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );

    return response.data;
}

export async function checkUsername(data) {
    const response = await axios.post(`${usersUrlEndpoint}/check_username`, { username: data },
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    )
    return response.data;
}

// PORTAL USERS  API
const PORTAL_USERS = '/users/get_portal_users';
const GET_PORTAL_USER_BYID = '/users/get_portal_user';
const ADD_PORTAL_USER = '/users/add_portal_user';
const UPDATE_PORTAL_USER = '/users/update_portal_user';
const DELETE_PORTAL_USER = '/users/delete_portal_user';


export async function getPortalUsers() {
    const response = await axios.get(PORTAL_USERS, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}

export async function addPortalUser(data) {
    const response = await axios.post(ADD_PORTAL_USER, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}

export async function updatePortalUser(data) {
    const response = await axios.patch(`${UPDATE_PORTAL_USER}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getPortalUserById(id) {

    const response = await axios.get(`${GET_PORTAL_USER_BYID}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

export async function deletePortalUser(id) {

    const response = await axios.delete(`${DELETE_PORTAL_USER}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );

    return response.data;
}

// ROLES  API
const ROLES = '/roles/get_roles';
const GET_ROLE_BYID = '/roles/get_role';
const ADD_ROLE = '/roles/add_role';
const UPDATE_ROLE = '/roles/update_role';
const DELETE_ROLE = '/roles/delete_role';

export async function getRoles() {
    const response = await axios.get(ROLES, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}

export async function addRole(data) {
    const response = await axios.post(ADD_ROLE, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}

export async function updateRole(data) {
    const response = await axios.patch(`${UPDATE_ROLE}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getRoleById(id) {

    const response = await axios.get(`${GET_ROLE_BYID}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

export async function deleteRole(id) {

    const response = await axios.delete(`${DELETE_ROLE}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

// PERMISSIONS  API
const PERMISSIONS = '/permissions/get_permissions';
const GET_PERMISSION_BYID = '/permissions/get_permission';
const ADD_PERMISSION = '/permissions/add_permission';
const UPDATE_PERMISSION = '/permissions/update_permission';
const DELETE_PERMISSION = '/permissions/delete_permission';

export async function getPermissions() {
    const response = await axios.get(PERMISSIONS, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}

export async function addPermission(data) {
    const response = await axios.post(ADD_PERMISSION, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}

export async function updatePermission(data) {
    const response = await axios.patch(`${UPDATE_PERMISSION}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getPermissionById(id) {

    const response = await axios.get(`${GET_PERMISSION_BYID}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

export async function deletePermission(id) {

    const response = await axios.delete(`${DELETE_PERMISSION}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}


// NATIONALITY API
const nationalityUrlEndpoint = '/nationality';

export async function getNationality() {
    const response = () => {
        return new Promise(function (resolve, reject) {

            axios.get(`${nationalityUrlEndpoint}`, {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            }).then(response => {
                resolve(response);
            });
        });
    };
    let responseData = await response();
    return responseData.data;
}

export async function addNationality(data) {
    const response = await axios.post(`${nationalityUrlEndpoint}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function updateNationality(data) {
    const response = await axios.patch(`${nationalityUrlEndpoint}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getNationalityById(id) {

    const response = await axios.get(`${nationalityUrlEndpoint}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function deleteNationality(id) {
    const response = await axios.delete(`${nationalityUrlEndpoint}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

// IDENTIFICATION API
const identificationUrlEndpoint = '/identification'

export async function getIdentification() {
    const response = () => {
        return new Promise(function (resolve, reject) {

            axios.get(`${identificationUrlEndpoint}`, {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            }).then(response => {
                resolve(response);
            });
        });
    };
    let responseData = await response();
    return responseData.data;
}

export async function addIdentification(data) {
    const response = await axios.post(`${identificationUrlEndpoint}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function updateIdentification(data) {
    const response = await axios.patch(`${identificationUrlEndpoint}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getIdentificationById(id) {

    const response = await axios.get(`${identificationUrlEndpoint}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function deleteIdentification(id) {
    const response = await axios.delete(`${identificationUrlEndpoint}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

// RELATIONSHIP API
const RELATIONSHIP = '/relationship/get_relationship';
const GET_RELATIONSHIP_BYID = '/relationship/get_relationship';
const ADD_RELATIONSHIP = '/relationship/add_relationship';
const UPDATE_RELATIONSHIP = '/relationship/update_relationship';
const DELETE_RELATIONSHIP = '/relationship/delete_relationship';

export async function getRelationship() {
    const response = await axios.get(RELATIONSHIP, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}

export async function addRelationship(data) {
    const response = await axios.post(ADD_RELATIONSHIP, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function updateRelationship(data) {
    const response = await axios.patch(`${UPDATE_RELATIONSHIP}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getRelationshipById(id) {

    const response = await axios.get(`${GET_RELATIONSHIP_BYID}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function deleteRelationship(id) {
    const response = await axios.delete(`${DELETE_RELATIONSHIP}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
// ZONE API
const ALL_ZONES = '/zones/all_zones';
const GET_ZONE_BYID = '/zones/get_zone';
const GET_ZONE_BYNAME = '/zones/get_zone_by_name';
const ADD_ZONE = '/zones/add_zone';
const UPDATE_ZONE = '/zones/update_zone';
const DELETE_ZONE = '/zones/delete_zone';

export async function allZones() {
    const response = await axios.get(ALL_ZONES, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}

export async function addZone(data) {
    const response = await axios.post(ADD_ZONE, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function updateZone(data) {
    const response = await axios.patch(`${UPDATE_ZONE}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getZoneById(id) {

    const response = await axios.get(`${GET_ZONE_BYID}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

export async function getZoneByName(id) {
    const response = await axios.post(GET_ZONE_BYNAME, { id: id },
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

export async function deleteZone(id) {
    const response = await axios.delete(`${DELETE_ZONE}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

// LOCATIONS API
const ALL_LOCATIONS = '/locations/all_locations';
const GET_LOCATION_BYID = '/locations/get_location';
const GET_LOCATION_BYZONE_NAME = '/locations/get_location_by_zone_name';
const GET_LOCATION_BYNAME = '/locations/get_location_by_name';
const ADD_LOCATION = '/locations/add_location';
const UPDATE_LOCATION = '/locations/update_location';
const DELETE_LOCATION = '/locations/delete_location';

export async function allLocations() {
    const response = await axios.get(ALL_LOCATIONS, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}

export async function addLocation(data) {
    const response = await axios.post(ADD_LOCATION, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function updateLocation(data) {
    const response = await axios.patch(`${UPDATE_LOCATION}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getLocationById(id) {

    const response = await axios.get(`${GET_LOCATION_BYID}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getLocationByName(name) {

    const response = await axios.post(GET_LOCATION_BYNAME, { zone_name: name },
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getLocationByZoneName(zone_name) {

    const response = await axios.post(GET_LOCATION_BYZONE_NAME, { zone_name: zone_name },
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function deleteLocation(id) {
    const response = await axios.delete(`${DELETE_LOCATION}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

// HOLIDAYS API
const HOLIDAYS = '/holidays/get_holidays';
const GET_HOLIDAY_BYID = '/holidays/get_holiday';
const ADD_HOLIDAY = '/holidays/add_holiday';
const UPDATE_HOLIDAY = '/holidays/update_holiday';
const DELETE_HOLIDAY = '/holidays/delete_holiday';

export async function getHolidays() {
    const response = await axios.get(HOLIDAYS, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}

export async function addHoliday(data) {
    const response = await axios.post(ADD_HOLIDAY, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function updateHoliday(data) {
    const response = await axios.patch(`${UPDATE_HOLIDAY}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getHolidayById(id) {

    const response = await axios.get(`${GET_HOLIDAY_BYID}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function deleteHoliday(id) {
    const response = await axios.delete(`${DELETE_HOLIDAY}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
// BROKERS API
const BROKERS = '/brokers/get_brokers';
const GET_BROKER_BYID = '/brokers/get_broker';
const ADD_BROKER = '/brokers/add_broker';
const UPDATE_BROKER = '/brokers/update_broker';
const DELETE_BROKER = '/brokers/delete_broker';

export async function getBrokers() {
    const response = await axios.get(BROKERS, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}

export async function addBroker(data) {
    const response = await axios.post(ADD_BROKER, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function updateBroker(data) {
    const response = await axios.patch(`${UPDATE_BROKER}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getBrokerById(id) {

    const response = await axios.get(`${GET_BROKER_BYID}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function deleteBroker(id) {
    const response = await axios.delete(`${DELETE_BROKER}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

// BANKS API
const BANKS = '/banks/get_banks';
const GET_BANK_BYID = '/banks/get_bank';
const ADD_BANK = '/banks/add_bank';
const UPDATE_BANK = '/banks/update_bank';
const DELETE_BANK = '/banks/delete_bank';
const SEARCH_BANK = '/banks/search_bank';

export async function getBanks() {
    await delay()
    const response = await axios.get(BANKS, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}

export async function addBank(data) {
    const response = await axios.post(ADD_BANK, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function updateBank(data) {
    const response = await axios.patch(`${UPDATE_BANK}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getBankById(id) {

    // await delay()
    const response = await axios.get(`${GET_BANK_BYID}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function deleteBank(id) {
    const response = await axios.delete(`${DELETE_BANK}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function searchBank(data) {
    const response = await axios.post(SEARCH_BANK,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
        },
        { data: data }
    );
    return response.data
}
// BANK BRANCHES API
const BANK_BRANCHES = '/banks/get_branches';
const ADD_BANK_BRANCH = '/banks/add_branch';
const GET_BANK_BRANCH_BYID = '/banks/get_branch';
const GET_BANK_BRANCH_BY_SORTCODE = '/banks/get_branch_by_sortcode';
const UPDATE_BANK_BRANCH = '/banks/update_branch';
const DELETE_BANK_BRANCH = '/banks/delete_branch';


export async function getBankBranches() {
    const response = await axios.get(BANK_BRANCHES,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        })
    return response.data;
}

export async function addBankBranch(data) {
    const response = await axios.post(ADD_BANK_BRANCH, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return (response.data);
}

export async function getBankBranchById(id) {
    const response = await axios.get(`${GET_BANK_BRANCH_BYID}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getBankBranchBySortcode(code) {
    const response = await axios.post(GET_BANK_BRANCH_BY_SORTCODE, { branch_sortcode: code }, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data
}

export async function updateBankBranch({ id, data }) {
    const response = await axios.patch(`${UPDATE_BANK_BRANCH}/${id}`, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    });
    return (response.data)
}

export async function deleteBankBranch(id) {
    const response = await axios.post(`${DELETE_BANK_BRANCH}/${id}`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    });
    return response.data
}


// STATUS API
const STATUS = '/status/get_status';
const GET_STATUS_BYID = '/status/get_status';
const ADD_STATUS = '/status/add_status';
const UPDATE_STATUS = '/status/update_status';
const DELETE_STATUS = '/status/delete_status';

export async function getStatus() {
    const response = await axios.get(STATUS, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}

export async function addStatus(data) {
    const response = await axios.post(ADD_STATUS, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function updateStatus(data) {
    const response = await axios.patch(`${UPDATE_STATUS}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getStatusById(id) {

    const response = await axios.get(`${GET_STATUS_BYID}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function deleteStatus(id) {
    const response = await axios.delete(`${DELETE_STATUS}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
// INVESTMENT CATEGORIES API
const INVESTMENT_CATEGORIES = '/investment_category/get_investment_categories';
const GET_INVESTMENT_CATEGORY_BYID = '/investment_category/get_investment_category';
const ADD_INVESTMENT_CATEGORY = '/investment_category/add_investment_category';
const UPDATE_INVESTMENT_CATEGORY = '/investment_category/update_investment_category';
const DELETE_INVESTMENT_CATEGORY = '/investment_category/delete_investment_category';

export async function getInvestmentCategories() {
    const response = await axios.get(INVESTMENT_CATEGORIES, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}

export async function addInvestmentCategories(data) {
    const response = await axios.post(ADD_INVESTMENT_CATEGORY, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function updateInvestmentCategories(data) {
    const response = await axios.patch(`${UPDATE_INVESTMENT_CATEGORY}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getInvestmentCategoriesById(id) {

    const response = await axios.get(`${GET_INVESTMENT_CATEGORY_BYID}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function deleteInvestmentCategories(id) {
    const response = await axios.delete(`${DELETE_INVESTMENT_CATEGORY}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

// TITLE API
const TITLES = '/titles/get_titles';
const GET_TITLE_BYID = '/titles/get_title';
const ADD_TITLE = '/titles/add_title';
const UPDATE_TITLE = '/titles/update_title';
const DELETE_TITLE = '/titles/delete_title';

export async function getTitles() {
    const response = await axios.get(TITLES, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}

export async function addTitle(data) {
    const response = await axios.post(ADD_TITLE, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function updateTitle(data) {
    const response = await axios.patch(`${UPDATE_TITLE}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getTitleById(id) {

    const response = await axios.get(`${GET_TITLE_BYID}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function deleteTitle(id) {
    const response = await axios.delete(`${DELETE_TITLE}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}


// FILE UPLOADS

const UPLOAD_MEMBERS = "/file_uploads/upload_drivers"
const UPLOAD_REGIONS = "/file_uploads/upload_regions"
const UPLOAD_CONSTITUENCIES = "/file_uploads/upload_constituencies"
const UPLOAD_BRANCHES = "/api/uploads/upload_branches"
const UPLOAD_TITLES = "/file_uploads/upload_titles"
const UPLOAD_EMAILS = "/file_uploads/upload_emails"
const UPLOAD_BANKS = "/file_uploads/upload_banks"
const UPLOAD_BANK_BRANCHES = "/file_uploads/upload_bank_branches"

export async function uploadMembers(data) {
    const response = await axios.post(UPLOAD_MEMBERS, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function uploadRegions(data) {
    const response = await axios.post(UPLOAD_REGIONS, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function uploadConstituencies(data) {
    const response = await axios.post(UPLOAD_CONSTITUENCIES, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function uploadBranches(data) {
    const response = await axios.post(UPLOAD_BRANCHES, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function uploadTitles(data) {
    const response = await axios.post(UPLOAD_TITLES, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function uploadBanks(data) {
    const response = await axios.post(UPLOAD_BANKS, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function uploadBankBranches(data) {
    const response = await axios.post(UPLOAD_BANK_BRANCHES, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function uploadEmails(data) {
    const response = await axios.post(UPLOAD_EMAILS, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}


// CATEGORY  API
const CATEGORIES = '/categories/get_categories';
const GET_CATEGORY_BYID = '/categories/get_category';
const ADD_CATEGORY = '/categories/add_category';
const UPDATE_CATEGORY = '/categories/update_category';
const DELETE_CATEGORY = '/categories/delete_category';

export async function getCategories() {
    const response = await axios.get(CATEGORIES, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}

export async function addCategory(data) {
    const response = await axios.post(ADD_CATEGORY, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}

export async function updateCategory(data) {
    const response = await axios.patch(`${UPDATE_CATEGORY}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getCategoryById(id) {
    const response = await axios.get(`${GET_CATEGORY_BYID}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

export async function deleteCategory(id) {
    const response = await axios.delete(`${DELETE_CATEGORY}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

// PRODUCTS  API
const PRODUCTS = '/products/get_products';
const GET_PRODUCT_BYID = '/products/get_product';
const ADD_PRODUCT = '/products/add_product';
const UPDATE_PRODUCT = '/products/update_product';
const DELETE_PRODUCT = '/products/delete_product';

export async function getProducts() {
    const response = await axios.get(PRODUCTS, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}

export async function addProduct(data) {
    const response = await axios.post(ADD_PRODUCT, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}

export async function updateProduct(data) {
    const response = await axios.patch(`${UPDATE_PRODUCT}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getProductById(id) {
    const response = await axios.get(`${GET_PRODUCT_BYID}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

export async function deleteProduct(id) {
    const response = await axios.delete(`${DELETE_PRODUCT}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}



const ALL_LEDGERS = '/ledgers/all_ledgers';
const GET_LEDGER_BY_ID = '/ledgers/get_ledger';
const GET_LEDGER = '/ledgers/get_ledger';
const UPDATE_LEDGER = '/ledgers/update_ledger';
const DELETE_LEDGER = '/ledgers/delete_ledger';
const SAVE_LEDGER = '/ledgers/save_ledger';
const CHECK_LEDGER_CONTROL = '/ledgers/check_ledger_control';
const CREATE_YEAR_LEDGERS = '/ledgers/create_year_ledgers';
const GOOD_STANDING_MEMBERS = "/ledgers/drivers_in_goodstanding"
const MEMBERS_IN_ARREARS = "/ledgers/drivers_in_arrears"

const SEARCH_LEDGER = '/ledgers/search_ledger';
const SEARCH_ARREARS = '/ledgers/search_arrears';
const SEARCH_GOODSTANDING = '/ledgers/search_goodstanding';

export async function getAllLedgers() {
    const response = await axios.get(ALL_LEDGERS, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}
export async function getLedgerByID(id) {
    const response = await axios.get(GET_LEDGER_BY_ID + `/${id}`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    });
    return response.data;
}
export async function saveLedger(data) {
    const response = await axios.post(SAVE_LEDGER, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}
export async function getLedger(data) {
    const response = await axios.post(GET_LEDGER, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}

export async function updateLedger(data) {
    const response = await axios.post(UPDATE_LEDGER, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    });
    return response.data
}
export async function deleteLedger(data) {
    const response = await axios.post(DELETE_LEDGER, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    });
    return response.data
}
export async function searchMemberLedger(data) {
    const response = await axios.post(SEARCH_LEDGER, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    });
    return response.data
}
export async function searchArrears(data) {
    const response = await axios.post(SEARCH_ARREARS, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    });
    return response.data
}
export async function searchGoodStanding(data) {
    const response = await axios.post(SEARCH_GOODSTANDING, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    });
    return response.data
}
export async function checkLedgerControl(year) {
    const response = await axios.post(CHECK_LEDGER_CONTROL, { year: year }, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    });
    return response.data
}
export async function createYearLedgers(data) {
    const response = await axios.post(CREATE_YEAR_LEDGERS, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    });
    return response.data
}

export const getGoodStandingMembers = async () => {
    const response = await axios.get(GOOD_STANDING_MEMBERS, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data
}
export const getArrearsMembers = async () => {
    const response = await axios.get(MEMBERS_IN_ARREARS, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data
}



// DASHBOARD APIs
const VERIFY_USER = '/dashboard/verify_user';
const DASHBOARD_INFO = '/dashboard/get_dashboard_info';
const DASHBOARD = '/dashboard/get_dashboard';
const MEMBERSHIP_SUMMARY = '/dashboard/drivership_summary';

export async function verifyUser() {
    const response = await axios.get(VERIFY_USER, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}

export async function getDashboardInfo() {
    const response = await axios.get(DASHBOARD_INFO, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data
}
export async function getDashboard() {
    const response = await axios.get(DASHBOARD, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data
}
export async function drivershipSummary() {
    const response = await axios.get(MEMBERSHIP_SUMMARY, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data
}


// DRIVER API
export const driversUrlEndpoint = '/drivers';

export async function getAllDrivers() {
    await delay()
    const response = await axios.get(`${driversUrlEndpoint}`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    });
    return response.data;
}
export async function getDriverById(id) {
    const response = await axios.get(`${driversUrlEndpoint}/${id}`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}
export async function addDriver(data) {
    const response = await axios.post(`${driversUrlEndpoint}`, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    });
    return response.data;
}

export async function updateDriver(data) {
    const response = await axios.patch(`${driversUrlEndpoint}/${data.id}`, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    });
    return (response.data)
}

export async function deleteDriver(id) {
    const response = await axios.delete(`${driversUrlEndpoint}/${id}`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    });
    return (response.data)
}

export async function searchDriver(data) {
    const response = await axios.post(`${driversUrlEndpoint}/search_driver`, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return (response.data)
}
export async function generalSearch(data) {
    const response = await axios.post(`${driversUrlEndpoint}/general_search`, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return (response.data)
}
export async function specialSearch(data) {
    const response = await axios.post(`${driversUrlEndpoint}/special_search`, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return (response.data)
}
export async function searchDriverPayment(data) {
    const response = await axios.post(`${driversUrlEndpoint}`, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return (response.data)
}
export async function searchDriverAccount(data) {
    const response = await axios.post(`${driversUrlEndpoint}`, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return (response.data)
}
export async function findDriverByCode(code) {
    const response = await axios.post(`${driversUrlEndpoint}`, { driver_code: code }, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data
}
export async function findDriverByCarNumber(car_number) {
    const response = await axios.post(`${driversUrlEndpoint}/find_driver_by_car_number`, { car_number: car_number }, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data
}
export async function findDriverByMobileNumber(mobile_number) {
    const response = await axios.post(`${driversUrlEndpoint}/find_driver_by_mobile_number`, { mobile_number: mobile_number }, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data
}

export async function pendingApprovals() {
    const response = await axios.get(driversUrlEndpoint, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    });
    return response.data
}
export async function approveRegistration(data) {
    const response = await axios.post(driversUrlEndpoint, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    });
    return response.data
}
export async function getApproveDriver(id) {
    const response = await axios.post(driversUrlEndpoint, { id: id }, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    });
    return response.data
}
export async function approvedDrivers() {
    const response = await axios.get(driversUrlEndpoint, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    });
    return response.data
}

const paymentListURLEndpoint = '/payment_list'

export async function getPaymentList() {
    const response = await axios.get(`${paymentListURLEndpoint}`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}

export async function getPaymentListById(id) {
    const response = await axios.get(`${paymentListURLEndpoint}/${id}`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data
}

export async function addPaymentList(data) {
    const response = await axios.post(`${paymentListURLEndpoint}`, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}

export async function updatePaymentList(data) {
    const response = await axios.patch(`${paymentListURLEndpoint}/${data.id}`, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    });
    return response.data
}

export async function deletePaymentList(id) {
    const response = await axios.delete(`${paymentListURLEndpoint}/${id}`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    });
    return response.data
}
export async function getPreviousPaymentList(year) {
    const response = await axios.post(`${paymentListURLEndpoint}/previous_payment_list`, { year: year }, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}


const paymentTypesURLEndpoint = '/payment_types'
export async function getPaymentTypes() {
    const response = () => {
        return new Promise(function (resolve, reject) {

            axios.get(`${paymentTypesURLEndpoint}`, {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            }).then(response => {
                resolve(response);
            });
        });
    };
    let responseData = await response();
    return responseData.data;
}

export async function getOtherPaymentTypes() {
    const response = await axios.get(`${paymentTypesURLEndpoint}/other_types`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}

export async function getPaymentTypeById(id) {
    const response = await axios.get(`${paymentTypesURLEndpoint}/${id}`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data
}

export async function addPaymentType(data) {
    const response = await axios.post(`${paymentTypesURLEndpoint}`, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}

export const updatePaymentType = async (data) => {
    const response = await axios.patch(`${paymentTypesURLEndpoint}/${data.id}`, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    });
    return response.data
}

export async function deletePaymentType(id) {
    const response = await axios.delete(`${paymentTypesURLEndpoint}/${id}`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    });
    return response.data
}

export async function getPaymentTypeByCode(code) {
    const response = await axios.post(`${paymentTypesURLEndpoint}/type_bycode`, { type_code: code }, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}

const PAYMENT_MODES = '/pay_modes/get_payment_modes';
const GET_PAYMENT_MODE_BYID = '/pay_modes/get_payment_mode';
const ADD_PAYMENT_MODE = '/pay_modes/add_payment_mode';
const UPDATE_PAYMENT_MODE = '/pay_modes/update_payment_mode';
const DELETE_PAYMENT_MODE = '/pay_modes/delete_payment_mode';
const GET_PAYMENT_MODE_BYCODE = '/pay_modes/get_payment_mode_by_code';


export async function getPaymentModes() {
    const response = await axios.get(PAYMENT_MODES, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}

export async function getPaymentModeById(id) {
    const response = await axios.get(GET_PAYMENT_MODE_BYID + `/${id}`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data
}

export async function addPaymentMode(data) {
    const response = await axios.post(ADD_PAYMENT_MODE, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}

export const updatePaymentMode = async (data) => {
    const response = await axios.patch(UPDATE_PAYMENT_MODE + `/${data.id}`, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    });
    return response.data
}

export async function deletePaymentMode(id) {
    const response = await axios.delete(DELETE_PAYMENT_MODE + `/${id}`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    });
    return response.data
}

export async function getPaymentModeByCode(code) {
    const response = await axios.post(GET_PAYMENT_MODE_BYCODE, { payment_mode: code }, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}


const paymentsUrlEndPoint = '/payments'

// PAYMENT APIs
export async function getAllPayments() {
    const response = await axios.get(`${paymentsUrlEndPoint}`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}
export async function getPaymentById(id) {
    const response = await axios.get(`${paymentsUrlEndPoint}/${id}`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    });
    return (response.data);
}
export async function addPayment(data) {
    const response = await axios.post(`${paymentsUrlEndPoint}`, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return (response.data);
}
export async function addMomoPayment(data) {
    const response = await axios.post(`${paymentsUrlEndPoint}/add_momo_payment`, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}
export async function getPayments(data) {
    const response = await axios.post(`${paymentsUrlEndPoint}`, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return (response.data);
}
export async function getPaymentsByMode(data) {
    const response = await axios.post(`${paymentsUrlEndPoint}`, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return (response.data);
}
export async function getDriverPaymentByCode(code) {
    const response = await axios.post(`${paymentsUrlEndPoint}`, { tenant_code: code }, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return (response.data);
}
export async function getPaymentByTransactionId(trans_id) {
    const response = await axios.post(`${paymentsUrlEndPoint}`, { transaction_id: trans_id }, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return (response.data);
}
export async function getPaymentByPf(pf_number) {
    const response = await axios.post(`${paymentsUrlEndPoint}`, { pf_number: pf_number }, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    });
    return response.data;
}

export async function updateAccount(data) {
    const response = await axios.post(`${paymentsUrlEndPoint}`, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    });
    return (response.data)
}

export async function detailedStatement(data) {
    const response = await axios.post(`${paymentsUrlEndPoint}`, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return (response.data);
}
export async function summaryStatement(data) {
    const response = await axios.post(`${paymentsUrlEndPoint}`, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return (response.data);
}
export async function generatePDF(data) {
    const response = await axios.post(`${paymentsUrlEndPoint}`, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return (response.data);
}

export async function generateDetailedStatement(data) {
    const response = await axios.post(`${paymentsUrlEndPoint}`, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return (response.data);
}
export async function generateSummaryStatement(data) {
    const response = await axios.post(`${paymentsUrlEndPoint}`, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return (response.data);
}
export async function addCardPayment(data) {
    const response = await axios.post(`${paymentsUrlEndPoint}`, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}
export async function addChequePayment(data) {
    const response = await axios.post(`${paymentsUrlEndPoint}`, data)
    return response.data;
}
export async function saveOnlinePayment(data) {
    const response = await axios.post(`${paymentsUrlEndPoint}`, data)
    return response.data;
}

export async function searchPayment(data) {
    const response = await axios.post(`${paymentsUrlEndPoint}`, data)
    return response.data
}

export async function captureMomoPayment(data) {
    const response = await axios.post(`${paymentsUrlEndPoint}/capture_momo`, data, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}



const clearanceUrlEndpoint = '/clearance'

export async function getPaymentClearance() {
    const response = await axios.get(`${clearanceUrlEndpoint}`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}
export async function findTransactionIDInfo(transaction_id) {
    const response = await axios.post(`${clearanceUrlEndpoint}`, { transaction_id: transaction_id }, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}
export async function getPaymentClearanceById(id) {
    const response = await axios.get(`${clearanceUrlEndpoint}/${id}`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}
export async function updatePaymentClearance(data) {
    return await axios.patch(`${clearanceUrlEndpoint}/${data.id}`, data);

}
export async function deletePaymentClearance(id) {
    return await axios.delete(`${clearanceUrlEndpoint}/${id}`);

}


// INTEREST API
const INTEREST_RATES = '/rates/interest_rates';
const INTEREST_BYID = '/rates/interest_rate';
const ADD_INTEREST = '/rates/add_interest_rate';
const UPDATE_INTEREST = '/rates/update_interest_rate';
const DELETE_INTEREST = '/rates/delete_interest_rate';

export async function getInterestRates() {
    const response = await axios.get(INTEREST_RATES, {
        withCredentials: true
    })
    return response.data;
}

export async function addInterestRates(data) {
    const response = await axios.post(ADD_INTEREST, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function updateInterestRates(data) {
    const response = await axios.patch(`${UPDATE_INTEREST}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getInterestRatesById(id) {

    const response = await axios.get(`${INTEREST_BYID}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

export async function deleteInterestRates(id) {
    const response = await axios.delete(`${DELETE_INTEREST}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}



// SALES REPS API
const SALES_REPS = '/sales_reps/get_sales_reps';
const GET_SALES_REP_BYID = '/sales_reps/get_sales_rep';
const ADD_SALES_REP = '/sales_reps/add_sales_rep';
const UPDATE_SALES_REP = '/sales_reps/update_sales_rep';
const DELETE_SALES_REP = '/sales_reps/delete_sales_rep';

export async function getSalesReps() {
    const response = await axios.get(SALES_REPS, {
        withCredentials: true
    })
    return response.data;
}

export async function addSalesRep(data) {
    const response = await axios.post(ADD_SALES_REP, data, {
        withCredentials: true
    })
    return response.data;
}

export async function updateSalesRep(data) {
    const response = await axios.patch(`${UPDATE_SALES_REP}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getSalesRepById(id) {

    const response = await axios.get(`${GET_SALES_REP_BYID}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

export async function deleteSalesRep(id) {

    const response = await axios.delete(`${DELETE_SALES_REP}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}


// SIGNATORIES API
const SIGNATURES = '/signatures/get_signatures';
const GET_SIGNATURE_BYID = '/signatures/get_signature';
const ADD_SIGNATURE = '/signatures/add_signature';
const UPDATE_SIGNATURE = '/signatures/update_signature';
const DELETE_SIGNATURE = '/signatures/delete_signature';

export async function getSignatures() {
    const response = await axios.get(SIGNATURES, {
        withCredentials: true
    })
    return response.data;
}

export async function addSignature(data) {
    const response = await axios.post(ADD_SIGNATURE, data, {
        withCredentials: true
    })
    return response.data;
}

export async function updateSignature(data) {
    const response = await axios.patch(`${UPDATE_SIGNATURE}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getSignatureById(id) {

    const response = await axios.get(`${GET_SIGNATURE_BYID}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

export async function deleteSignature(id) {

    const response = await axios.delete(`${DELETE_SIGNATURE}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}



const SAVE_RECEIPT = '/receipts/save_payment_receipt';

export async function savePaymentReceipt(data) {
    const response = await axios.post(SAVE_RECEIPT, data)
    return response.data;
}



// PATHS  API
export const pathsUrlEndpoint = '/paths';
export async function getPaths() {
    const response = await axios.get(pathsUrlEndpoint, {
        withCredentials: true
    })
    return response.data;
}

export async function addPath(data) {
    const response = await axios.post(pathsUrlEndpoint, data, {
        withCredentials: true
    })
    return response.data;
}

export async function updatePath(data) {
    const response = await axios.patch(`${pathsUrlEndpoint}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getPathById(id) {
    const response = await axios.get(`${pathsUrlEndpoint}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

export async function deletePath(id) {
    const response = await axios.delete(`${pathsUrlEndpoint}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}


// STOPS  API
export const stopsUrlEndpoint = '/stops';
export async function getStops() {
    const response = await axios.get(stopsUrlEndpoint, {
        withCredentials: true
    })
    return response.data;
}

export async function addStop(data) {
    const response = await axios.post(stopsUrlEndpoint, data, {
        withCredentials: true
    })
    return response.data;
}

export async function updateStop(data) {
    const response = await axios.patch(`${stopsUrlEndpoint}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getStopById(id) {
    const response = await axios.get(`${stopsUrlEndpoint}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

export async function deleteStop(id) {
    const response = await axios.delete(`${stopsUrlEndpoint}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}




// MONTHLY SUBSCRIPTION
const subscriptionUrEndPoint = '/monthly_subscriptions';

export async function getSubscriptions() {
    const response = await axios.get(subscriptionUrEndPoint, {
        withCredentials: true
    })
    return response.data;
}

export async function addSubscription(data) {
    const response = await axios.post(subscriptionUrEndPoint, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function updateSubscription(data) {
    const response = await axios.patch(`${subscriptionUrEndPoint}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getSubscriptionById(id) {

    const response = await axios.get(`${subscriptionUrEndPoint}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

export async function deleteSubscription(id) {
    const response = await axios.delete(`${subscriptionUrEndPoint}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

// REGISTRATION FEES
const registrationFeesUrEndPoint = '/reg_fees';

export async function getRegistrationFees() {
    const response = await axios.get(`${registrationFeesUrEndPoint}`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    return response.data;
}

export async function addRegistrationFee(data) {
    const response = await axios.post(`${registrationFeesUrEndPoint}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

export async function updateRegistrationFee(data) {
    const response = await axios.patch(`${registrationFeesUrEndPoint}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

export async function getRegistrationFeeById(id) {

    const response = await axios.get(`${registrationFeesUrEndPoint}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

export async function deleteRegistrationFee(id) {
    const response = await axios.delete(`${registrationFeesUrEndPoint}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}


// REGISTRATION FEE PAYMENT
const regFeePaymentUrlEndpoint = '/fee_payment'

export async function getRegFeePayments() {
    const response = await axios.get(`${regFeePaymentUrlEndpoint}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

export async function checkRegFeePayment(data) {
    const response = await axios.post(`${regFeePaymentUrlEndpoint}/check_fee_payment`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function searchRegFeePayment(data) {
    const response = await axios.post(`${regFeePaymentUrlEndpoint}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

// DEVICES API
const devicesUrEndPoint = '/devices';

export async function getDevices() {
    const response = await axios.get(devicesUrEndPoint, {
        withCredentials: true
    })
    return response.data;
}

export async function addDevice(data) {
    const response = await axios.post(devicesUrEndPoint, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function updateDevice(data) {
    const response = await axios.patch(`${devicesUrEndPoint}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getDeviceById(id) {

    const response = await axios.get(`${devicesUrEndPoint}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

export async function deleteDevice(id) {
    const response = await axios.delete(`${devicesUrEndPoint}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
