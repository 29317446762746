import { createContext, useState } from "react"
import toast, { Toaster } from 'react-hot-toast'
const RegistrationContext = createContext({})

export const RegistrationProvider = ({ children }) => {


    const title = {
        0: 'PERSONAL INFORMATION',
        1: 'CONTACT INFORMATION',
        2: 'NEXT OF KIN',
        3: 'REFERENCE PERSON',
        4: 'TERMS & CONDITIONS',
        5: 'PAYMENT INFORMATION',
    }

    let nYear = 10
    const [page, setPage] = useState(0)
    const [phone_name, setPhoneName] = useState("")

    const [data, setData] = useState({
        persLastname: "",
        persFirstname: "",
        persGender: "",
        persDOB: "",
        persNationality: "",
        persIdentificationType: "",
        persIdNumber: "",
        persDateIssued: "",
        persExpiryDate: "",
        persMaritalStatus: "",
        persPhoto: "",

        contactCarNumber: "",
        contactMobileNumber: "",
        contactEmail: "",
        contactSpouseMobileNumber: "",
        contactGpsAddress: "",


        nkinNextOfKin: "",
        nkinMobileNumber: "",
        nkinRelationship: "",
        refpReferencePerson: "",
        refpReferenceMobileNumber: "",

        agreeTerms: true,
    })

    const handleOnPhoneInput = (e) => {
        const name = e.target.placeholder
        setPhoneName(name)
    }

    const handlePhoneInput = (e) => {
        const value = e
        const name = phone_name ? phone_name : undefined
        setData(prevData => ({
            ...prevData,
            [name]: value
        }))
    }

    const handlePhoto = (e) => {
        let file = e.target.files[0];
        const name = e.target.name
        if (file) {
            if (maxSelectedImage(e) && checkMimeType(e) && checkFileSize(e)) {
                setData(prevData => ({
                    ...prevData,
                    [name]: file
                }))
            }
        }
    };

    const maxSelectedImage = (e) => {
        let file = e.target.files[0];
        if (file.length > 1) {
            const msg = 'Only 1 images can be uploaded at a time';
            e.target.value = null; // discard selected file
            toast.error(msg)
            return false;
        } else {
            return true;
        }
    }
    const checkMimeType = (e) => {
        // get file object
        let file = e.target.files[0];
        let err = [];
        const types = ["image/jpg", "image/jpeg", "image/png", "image/gif", "image/svc"]
        for (var i = 0; i < file.length; i++) {
            // eslint-disable-next-line
            if (types.every(type => file[i].type !== type)) {
                err[i] = file[i].type + ' is not a supported format\n';
            };
        };
        for (var x = 0; x < err.length; x++) {
            e.target.value = null;
            toast.error(err[x])
        }
        return true;
    }
    const checkFileSize = (e) => {
        let file = e.target.files[0];
        let size = 2000000;
        let err = [];
        for (var i = 0; i < file.length; i++) {
            if (file[i].size > size) {
                err[i] = file[i].type + ' is too large. Please pick a small image\n';
            };
        };
        for (var x = 0; x < err.length; x++) {
            e.target.value = null;
            toast.error(err[x])
        }
        return true;
    }


    const handleExpiryDate = e => {
        const value = e.target.value
        let expDate = addYears(value, nYear)

        setData(prevData => ({
            ...prevData,
            persDateIssued: value,
            persExpiryDate: expDate.toLocaleDateString("sv-SE")
        }))
    }

    const addYears = (dDate, nyr) => {
        let issuedDate = new Date(dDate)
        let backDate = new Date(issuedDate.setDate(issuedDate.getDate() - 1))
        return new Date(backDate.setFullYear(backDate.getFullYear() + nyr));
    }

    const handleAgreement = e => {
        const name = e.target.name
        const value = e.target.checked

        setData(prevData => ({
            ...prevData,
            [name]: value
        }))
    }
    
    const handleChange = e => {

        const type = e.target.type

        const name = e.target.name

        const value = type === "checkbox"
            ? e.target.checked
            : e.target.value

        setData(prevData => ({
            ...prevData,
            [name]: value
        }))

    }

    const {
        persPhoto,
        contactSpouseMobileNumber,
        contactGpsAddress,
        refpReferencePerson,
        refpReferenceMobileNumber,
        ...requiredInputs
    } = data


    const canSubmit = [...Object.values(requiredInputs)].every(Boolean) && page === Object.keys(title).length - 1

    const canNextPage0 = Object.keys(data)
        .filter(key => key.startsWith('pers')
            && key !== 'persPhoto'
        )
        .map(key => data[key])
        .every(Boolean)

    const canNextPage1 = Object.keys(data)
        .filter(key => key.startsWith('contact')
            && key !== 'contactPostalAddress'
            && key !== 'contactSpouseMobileNumber'
            && key !== 'contactGpsAddress'
        )
        .map(key => data[key])
        .every(Boolean)

    const canNextPage2 = Object.keys(data)
        .filter(key => key.startsWith('nkin'))
        .map(key => data[key])
        .every(Boolean)

    const canNextPage3 = Object.keys(data)
        .filter(key => key.startsWith('refp')
            && key !== 'refpReferencePerson'
            && key !== 'refpReferenceMobileNumber'
        )
        .map(key => data[key])
        .every(Boolean)

    const canNextPage4 = Object.keys(data)
        .filter(key => key.startsWith('agree'))
        .map(key => data[key])
        .every(Boolean)

    const disablePrev = page === 0

    const disableNext =
        (page === Object.keys(title).length - 1)
        || (page === 0 && !canNextPage0)
        || (page === 1 && !canNextPage1)
        || (page === 2 && !canNextPage2)
        || (page === 3 && !canNextPage3)
        || (page === 4 && !canNextPage4)


    const prevHide = page === 0 && "remove-button"

    const nextHide = page === Object.keys(title).length - 1 && "remove-button"

    const submitHide = page !== Object.keys(title).length - 1 && "remove-button"


    return (
        <RegistrationContext.Provider value={{
            title,
            page,
            setPage,
            data,
            setData,
            canSubmit,
            handleOnPhoneInput,
            handleChange,
            handlePhoneInput,
            handlePhoto,
            handleExpiryDate,
            handleAgreement,
            disablePrev,
            disableNext,
            prevHide,
            nextHide,
            submitHide
        }}>
            <Toaster toastOptions={{ position: "top-center" }} />
            {children}
        </RegistrationContext.Provider>
    )
}

export default RegistrationContext 