import FormSetup from "./FormSetup"
import { RegistrationProvider } from '../../../context/RegistrationContext'

function Registration() {

    return (
        <>
            <RegistrationProvider>
                <FormSetup />
            </RegistrationProvider>
        </>
    )
}

export default Registration;