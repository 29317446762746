import React from "react"
// import Awards from "./awards/Awards"
import Hero from "./hero/Hero"
import Price from "./price/Price"
import RegInfo from "./reginfo/RegInfo"
import Flyers from "../flyers/Flyers"
import FlyerText from "../flyers/FlyerText"

const Home = () => {
  return (
    <>
      <Hero />
      <FlyerText />
      <Flyers />
      <RegInfo />
      {/* <Price /> */}
    </>
  )
}

export default Home
