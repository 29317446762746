import React, { useState } from 'react'
import "./Driver.css"

const DriverCardRight = (props) => {

    return (
        <>
            <div className="driver-card-right">
                <legend><h2>DETAILED INFORMATION</h2></legend>
                <div className="driver-details">
                    <h3>CAR NUMBER : {props.car_number}</h3>
                    <h3>CAR NUMBER : {props.car_number}</h3>
                </div>
                <div className="driver-details">
                    <h3>CAR NUMBER : {props.car_number}</h3>
                    <h3>CAR NUMBER : {props.car_number}</h3>
                </div>
                <div className="driver-details">
                    <h3>CAR NUMBER : {props.car_number}</h3>
                    <h3>CAR NUMBER : {props.car_number}</h3>
                </div>
                <div className="driver-details">
                    <h3>CAR NUMBER : {props.car_number}</h3>
                    <h3>CAR NUMBER : {props.car_number}</h3>
                </div>
                <div className="driver-details">
                    <h3>CAR NUMBER : {props.car_number}</h3>
                    <h3>CAR NUMBER : {props.car_number}</h3>
                </div>
            </div>
        </>
    )
}

export default DriverCardRight