export const nav = [
  {
    text: "home",
    path: "/",
  },
  {
    text: "about",
    path: "/about",
  },
  {
    text: "services",
    path: "/services",
  },
  {
    text: "blog",
    path: "/blog",
  },
  {
    text: "pricing",
    path: "/pricing",
  },
  {
    text: "contact",
    path: "/contact",
  },
]

export const featured = [
  {
    cover: "/images/logo/crysec.png",
    name: "Security Equipment",
    total: "Cameras, Pepper Spray, and Panic Buttons integrated with a GPS Tracker for immediate response",
  },
  {
    cover: "/images/logo/crysec.png",
    name: "24/7 Response Team",
    total: "Highly trained professionals ready to assist drivers in distress",
  },
  {
    cover: "/images/logo/crysec.png",
    name: "Collaboration with Ghana Police",
    total: "Seamless integration with law enforcement for swift emergency response",
  },
  {
    cover: "/images/logo/crysec.png",
    name: "Target Market",
    total: `  - Private Car Owners,
              - Commercial Drivers (taxis,
                ride-sharing services, etc)
              `,
  },
]

export const list = [
  {
    id: 1,
    cover: "../images/list/p-1.png",
    name: "Red Carpet Real Estate",
    location: "210 Zirak Road, Canada",
    category: "For Rent",
    price: "$3,700",
    type: "Apartment",
  },
  {
    id: 2,
    cover: "../images/list/p-2.png",
    name: "Fairmount Properties",
    location: "5698 Zirak Road, NewYork",
    category: "For Sale",
    price: "$9,750",
    type: "Condos",
  },
  {
    id: 3,
    cover: "../images/list/p-7.png",
    name: "The Real Estate Corner",
    location: "5624 Mooker Market, USA",
    category: "For Rent",
    price: "$5,860",
    type: "Offices",
  },
  {
    id: 4,
    cover: "../images/list/p-4.png",
    name: "Herringbone Realty",
    location: "5621 Liverpool, London",
    category: "For Sale",
    price: "$7,540",
    type: "Homes & Villas",
  },
  {
    id: 5,
    cover: "../images/list/p-5.png",
    name: "Brick Lane Realty",
    location: "210 Montreal Road, Canada",
    category: "For Rent",
    price: "$4,850",
    type: "Commercial",
  },
  {
    id: 6,
    cover: "../images/list/p-6.png",
    name: "Banyon Tree Realty",
    location: "210 Zirak Road, Canada",
    category: "For Sale",
    price: "$2,742",
    type: "Apartment",
  },
]

export const awards = [
  {
    icon: <i className='fa-solid fa-trophy'></i>,
    num: "32 M	",
    name: "Blue Burmin Award",
  },
  {
    icon: <i className='fa-solid fa-briefcase'></i>,
    num: "43 M",
    name: "Mimo X11 Award",
  },
  {
    icon: <i className='fa-solid fa-lightbulb'></i>,
    num: "51 M",
    name: "Australian UGC Award",
  },
  {
    icon: <i className='fa-solid fa-heart'></i>,
    num: "42 M",
    name: "IITCA Green Award",
  },
]
export const location = [
  {
    id: 1,
    name: "New Orleans, Louisiana",
    Villas: "12 Villas",
    Apartments: "10 Apartments",
    Offices: "07 Offices",
    cover: "./images/location/city-1.png",
  },
  {
    id: 2,
    name: "Jerrsy, United State",
    Villas: "12 Villas",
    Apartments: "10 Apartments",
    Offices: "07 Offices",
    cover: "./images/location/city-2.png",
  },
  {
    id: 3,
    name: "Liverpool, London",
    Villas: "12 Villas",
    Apartments: " 10 Apartments",
    Offices: "07 Offices",
    cover: "./images/location/city-3.png",
  },
  {
    id: 4,
    name: "NewYork, United States",
    Villas: "12 Villas",
    Apartments: " 10 Apartments",
    Offices: "07 Offices",
    cover: "./images/location/city-4.png",
  },
  {
    id: 5,
    name: "Montreal, Canada",
    Villas: "12 Villas",
    Apartments: " 10 Apartments",
    Offices: "07 Offices",
    cover: "./images/location/city-5.png",
  },
  {
    id: 6,
    name: "California, USA",
    Villas: "12 Villas",
    Apartments: " 10 Apartments",
    Offices: "07 Offices",
    cover: "./images/location/city-6.png",
  },
]
export const team = [
  {
    list: "50",
    cover: "../images/customer/team-1.jpg",
    address: "Liverpool, Canada",
    name: "Sargam S. Singh",
    icon: [<i className='fa-brands fa-facebook-f'></i>, <i className='fa-brands fa-linkedin'></i>, <i className='fa-brands fa-twitter'></i>, <i className='fa-brands fa-instagram'></i>],
  },
  {
    list: "70",
    cover: "../images/customer/team-2.jpg",
    address: "Montreal, Canada",
    name: "Harijeet M. Siller",
    icon: [<i className='fa-brands fa-facebook-f'></i>, <i className='fa-brands fa-linkedin'></i>, <i className='fa-brands fa-twitter'></i>, <i className='fa-brands fa-instagram'></i>],
  },
  {
    list: "80",
    cover: "../images/customer/team-3.jpg",
    address: "Denever, USA",
    name: "Anna K. Young",
    icon: [<i className='fa-brands fa-facebook-f'></i>, <i className='fa-brands fa-linkedin'></i>, <i className='fa-brands fa-twitter'></i>, <i className='fa-brands fa-instagram'></i>],
  },
  {
    list: "51",
    cover: "../images/customer/team-4.jpg",
    address: "2272 Briarwood Drive",
    name: "Michael P. Grimaldo",
    icon: [<i className='fa-brands fa-facebook-f'></i>, <i className='fa-brands fa-linkedin'></i>, <i className='fa-brands fa-twitter'></i>, <i className='fa-brands fa-instagram'></i>],
  },
  {
    list: "42",
    cover: "../images/customer/team-5.jpg",
    address: "2272 Briarwood Drive",
    name: "Michael P. Grimaldo",
    icon: [<i className='fa-brands fa-facebook-f'></i>, <i className='fa-brands fa-linkedin'></i>, <i className='fa-brands fa-twitter'></i>, <i className='fa-brands fa-instagram'></i>],
  },
  {
    list: "38",
    cover: "../images/customer/team-5.jpg",
    address: "Montreal, USA",
    name: "Adam K. Jollio",
    icon: [<i className='fa-brands fa-facebook-f'></i>, <i className='fa-brands fa-linkedin'></i>, <i className='fa-brands fa-twitter'></i>, <i className='fa-brands fa-instagram'></i>],
  },
]
export const price = [
  {
    plan: "Monthly Subscription",
    price: "300.00",
    ptext: "per month",
    list: [
      {
        icon: <i className='fa-solid fa-check'></i>,
        text: "Tracking Device With Panic Button",
      },
      {
        icon: <i className='fa-solid fa-check'></i>,
        text: "24/7 Response Team",
      },
      {
        icon: <i className='fa-solid fa-check'></i>,
        text: "24/7 Monitoring",
      },
      { change: "color", icon: <i className='fa-solid fa-x'></i>, text: "Direct Contact" },
      { change: "color", icon: <i className='fa-solid fa-x'></i>, text: "Tracking Servicing" },
    ],
  },
  {
    best: "Best Value",
    plan: "Standard",
    price: "800",
    ptext: "per month",
    list: [
      {
        icon: <i className='fa-solid fa-check'></i>,
        text: "Tracking Device With Panic Button & Camera",
      },
      {
        icon: <i className='fa-solid fa-check'></i>,
        text: "24/7 Response Team",
      },
      {
        icon: <i className='fa-solid fa-check'></i>,
        text: "24/7 Monitoring",
      },
      {
        icon: <i className='fa-solid fa-check'></i>,
        text: "Direct Contact & Personal Help Support",
      },
      {
        change: "color",
        icon: <i className='fa-solid fa-x'></i>,
        text: "Tracking Servicing",
      },
    ],
  },
  {
    plan: "Platinum",
    price: "1200",
    ptext: "per month",
    list: [
      {
        icon: <i className='fa-solid fa-check'></i>,
        text: "Tracking Device With Panic Button, Camera & Pepper Spray",
      },
      {
        icon: <i className='fa-solid fa-check'></i>,
        text: "24/7 Response Team",
      },
      {
        icon: <i className='fa-solid fa-check'></i>,
        text: "24/7 Device Monitoring",
      },
      {
        icon: <i className='fa-solid fa-check'></i>,
        text: "Direct Contact & Personal Help Support",
      },
      {
        icon: <i className='fa-solid fa-check'></i>,
        text: "Device & Tracking Servicing",
      },
    ],
  },
]
export const footer = [
  {
    title: "LAYOUTS",
    text: [
      {
        list: "Home Page",
        path: '/'
      },
      {
        list: "About Page",
        path: '/about'
      },
      {
        list: "Service Page",
        path: '/services'
      },
      {
        list: "Property Page",
        path: '/property'
      },
      {
        list: "Contact Page",
        path: '/contact'
      },
      {
        list: "Single Blog", path: '/blob'
      }
    ],
  },
  {
    title: "ALL SECTIONS",
    text: [{ list: "Headers" }, { list: "Features" }, { list: "Attractive" }, { list: "Testimonials" }, { list: "Videos" }, { list: "Footers" }],
  },
  {
    title: "COMPANY",
    text: [{ list: "About" }, { list: "Blog" }, { list: "Pricing" }, { list: "Affiliate" }, { list: "Login" }, { list: "Changelog" }],
  },
]

export const services = [
  {

    header: "SECURITY EQUIPMENT ",

    list: [
      {
        icon: <i className='fa-solid fa-camera'></i>,
        text: " Cameras, pepper spray, and panic buttons integrated with a tracker for immediate response.",
      },
      {
        icon: <i className='fa-solid fa-check'></i>,
        text: "24/7 Response Team",
      },
      {
        icon: <i className='fa-solid fa-check'></i>,
        text: "24/7 Monitoring",
      },
      { change: "color", icon: <i className='fa-solid fa-x'></i>, text: "Direct Contact" },
      { change: "color", icon: <i className='fa-solid fa-x'></i>, text: "Tracking Servicing" },
    ],
  },
  {
    best: "Best Value",
    plan: "Standard",
    price: "800",
    ptext: "per month",
    list: [
      {
        icon: <i className='fa-solid fa-check'></i>,
        text: "Tracking Device With Panic Button & Camera",
      },
      {
        icon: <i className='fa-solid fa-check'></i>,
        text: "24/7 Response Team",
      },
      {
        icon: <i className='fa-solid fa-check'></i>,
        text: "24/7 Monitoring",
      },
      {
        icon: <i className='fa-solid fa-check'></i>,
        text: "Direct Contact & Personal Help Support",
      },
      {
        change: "color",
        icon: <i className='fa-solid fa-x'></i>,
        text: "Tracking Servicing",
      },
    ],
  },
  {
    plan: "Platinum",
    price: "1200",
    ptext: "per month",
    list: [
      {
        icon: <i className='fa-solid fa-check'></i>,
        text: "Tracking Device With Panic Button, Camera & Pepper Spray",
      },
      {
        icon: <i className='fa-solid fa-check'></i>,
        text: "24/7 Response Team",
      },
      {
        icon: <i className='fa-solid fa-check'></i>,
        text: "24/7 Device Monitoring",
      },
      {
        icon: <i className='fa-solid fa-check'></i>,
        text: "Direct Contact & Personal Help Support",
      },
      {
        icon: <i className='fa-solid fa-check'></i>,
        text: "Device & Tracking Servicing",
      },
    ],
  },
]

export const registrationInfo = [
  {
    cover: "/images/logo/crysec.png",
    name: "Registration Fee",
    info: "All clients are to pay registration fee to register with CRYSEC SERVICES",
    total: "GHS200.00",
  },
  {
    cover: "/images/logo/crysec.png",
    name: "Monthly Subscription Fee",
    info: "Regiatered Clients are to pay monthly subscription fee to enjoy the services we offer",
    total: "GHS300.00",
  },

  {
    cover: "/images/logo/crysec.png",
    name: "GPS Tracking Devices + Camera",
    info: `  One-time GPS tracking devices fitted with Panic Button and Camera plus 
    fixing`,
    total: `GHS1,500.00`,
  },

]

export const conditions = [
  {
    cover: "/images/logo/crysec.png",
    name: "Security Equipment",
    total: "Cameras, Pepper Spray, and Panic Buttons integrated with a GPS Tracker for immediate response",
  },
  {
    cover: "/images/logo/crysec.png",
    name: "24/7 Response Team",
    total: "Highly trained professionals ready to assist drivers in distress",
  },
  {
    cover: "/images/logo/crysec.png",
    name: "Collaboration with Ghana Police",
    total: "Seamless integration with law enforcement for swift emergency response",
  },
  {
    cover: "/images/logo/crysec.png",
    name: "Target Market",
    total: `  - Private Car Owners,
              - Commercial Drivers (taxis,
                ride-sharing services, etc)
              `,
  },

]
