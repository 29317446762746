import React, { useRef, useState, useEffect } from 'react';
import useAuth from '../../../hooks/useAuth';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    adminLogin,
    checkAdminUsername
} from '../../../api/apiServices';
import toast, { Toaster } from 'react-hot-toast'
import './Login.css';

const Login = () => {
    const { setAuth, persist, setPersist } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/dashboard";

    const userRef = useRef();
    const errRef = useRef();

    const [state, setState] = useState({
        username: "",
        password: "",
    })

    const [errMsg, setErrMsg] = useState('');

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [state.username, state.password])

    const handleUsername = async (e) => {
        e.preventDefault();

        let adminUsername = e.target.value;
        if (!adminUsername) {
            setErrMsg("  Please enter your username address");
            errRef.current.focus();
            return false;
        } else {
            try {
                let sendData = [];
                checkAdminUsername(adminUsername).then((hasPswd) => {
                    if (hasPswd.status === 'not_found') {
                        toast.error(hasPswd.message);
                        setErrMsg(hasPswd.message);
                        userRef.current.focus();
                    }
                    if (hasPswd.status === 'blocked') {
                        toast.error(hasPswd.message);
                        setErrMsg(hasPswd.message);
                        setState({
                            ...state,
                            username: "",
                        });
                        userRef.current.focus();
                    }
                    if (hasPswd.status === false) {
                        let useData = hasPswd.data;
                        function extractData() {
                            sendData = Object.keys(useData).map(function (key) {
                                return useData[key];
                            }).join('&');
                        }
                        extractData();
                        window.location.replace(`/set_password?user=${sendData}`);
                    } else {
                        return true
                    }
                }).catch((error) => {
                    console.log(error)
                })
            } catch (err) {
                if (err.response?.status === 400) {
                    setErrMsg('Missing Username or Password');
                } else if (err.response?.status === 401) {
                    setErrMsg('Username not found ');
                } else {
                    setErrMsg('Login failed');
                }
                errRef.current.focus();
            }
        }
    }
    const handleChange = (e) => {
        e.preventDefault();
        const value = e.target.value
        setState({
            ...state,
            [e.target.name]: value
        })

    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!state.username && !state.password) return false;
        const data = {
            username: state.username,
            password: state.password
        }
        try {
            adminLogin(data).then((response) => {
                if (response.status === true) {
                    const user_role = response?.user_role;
                    const username = response?.username;
                    const fullname = response?.fullname;
                    const user = response?.user;
                    const accessToken = response?.accessToken;

                    setAuth({ user_role, fullname, user, username, accessToken });
                    setState({
                        ...state,
                        username: "",
                        password: "",
                    });
                    navigate(from, { replace: true });
                }
                if (response.status === false) {
                    if (response.login_attempts === 3 || response.account_blocked) {
                        toast.error(response.message);
                        setState({
                            ...state,
                            pf_number: "",
                            password: "",
                        });
                        return false
                    } else {
                        toast.error(response.message);
                        setState({
                            ...state,
                            password: "",
                        });
                        return false
                    }
                }
            })
        } catch (err) {
            if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Wrong Username or Password');
            } else {
                console.log(err)
                setErrMsg('Login Failed');
            }
            errRef.current.focus();
        }
    }
    const togglePersist = () => {
        setPersist(prev => !prev);
    }

    useEffect(() => {
        localStorage.setItem('persist', persist)
    }, [persist])
    return (
        <div className="full-screen-container">
            <Toaster toastOptions={{ position: "top-center" }} />
            <div className="login-container">
                <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                <div className="login-image">
                    <img className='logo-img' src='/images/logo/crysec.png' alt='crysec' />
                </div>
                <form className="form" onSubmit={handleSubmit}>
                    <fieldset>
                        <legend className='login-title'>User Login</legend>
                        <div className="input-group success">
                            <input
                                type="username"
                                name="username"
                                ref={userRef}
                                id="username"
                                placeholder='Username'
                                autoComplete="off"
                                onBlur={handleUsername}
                                value={state.username}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="input-group error">
                            <input
                                name="password"
                                type="password"
                                id="password"
                                autoComplete="off"
                                value={state.password}
                                placeholder="Password"
                                required
                                onChange={handleChange}
                            />
                        </div>
                        <button type="submit" className="btn btn-success btn-block login-button">Login</button>
                        <div className='persistCheck'>
                            <input
                                type='checkbox'
                                id='persist'
                                onChange={togglePersist}
                                checked={persist ? persist : undefined}
                            />
                            <label htmlFor='persist'> Trust This Device</label>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    )
}

export default Login