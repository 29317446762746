import React from "react"
import Heading from "../../../common/Heading"
import "./hero.css"
import { useNavigate } from "react-router-dom"

const Hero = () => {
	const navigate = useNavigate()

	const handleTerms = () => {
		navigate("/terms_conditions")
	}
	const handleLogin = () => {
		navigate("/main_login")
	}
	const handleRegister = () => {
		navigate("/main_registration")
	}
	return (
		<>
			<section className='hero'>
				<div className='container'>
					<Heading
						title='OUR PLEDGE !!!'
						subtitle={`We are dedicated to enhancing the safety of drivers across
						 the country by offering a comprehensive security and safety solutions`} 
					/>
				</div>
				<div className="register">
				<button onClick={handleTerms} className='btn6'>Terms & Conditions</button>
				<button onClick={handleRegister} className='btn5'>Register</button>
				<button disabled onClick={handleLogin} className='btn5'>Login</button>
				</div>
			</section>
		</>
	)
}

export default Hero
