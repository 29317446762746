import React from "react"
import Heading from "../../../common/Heading"
import InfomationCard from "./InfomationCard"
import "./RegInfo.css"

const RegInfo = () => {
  return (
    <>
      <section className='info background'>
        <div className='container'>
          <Heading title='REGISTRATION DETAILS'
            subtitle='' />
          <InfomationCard />
        </div>
      </section>
    </>
  )
}

export default RegInfo
