import React, { useState } from 'react'
import config from '../../admin/gMap/config';

const Location = () => {
    const[latitude, setLatitude]= useState()
    const [longitude, setLongitude] = useState()
    const [address, setAddress] = useState()
    const[gps_latitude, setGPSLatitude]= useState()
    const [gps_longitude, setGPSLongitude] = useState()

    const geo = navigator.geolocation

    // Get Driver Current Location
    geo.getCurrentPosition(driverCoordinates)
    function driverCoordinates(position) {
        let driverLatitude = position.coords.latitude
        let driverLongitude = position.coords.longitude
        // console.log("Latitude: " + driverLatitude)
        // console.log("Longitude: " + driverLongitude)
        setLatitude(driverLatitude)
        setLongitude(driverLongitude)
    }

    const getDriverAddress = async () => {
        let url = `https://api.opencagedata.com/geocode/v1/json?key=7e5b1ebb09c749ffa3da1d91d691e610&q=${latitude}%2C+${longitude}&pretty=1&no_annotations=1`;
        const loc = await fetch(url)
        const data = await loc.json()
        console.log("Driver Address: " + data.results[0].components.city)
        setAddress(data.results[0].formatted)
    }

    const handleGetDriverAddress = async () => {
        await getDriverAddress()
    }

    // Get Driver GPS Current Location
    geo.watchPosition(driverGPSCoordinates)
    function driverGPSCoordinates(position) {
        let driverGPSLatitude = position.coords.latitude
        let driverGPSLongitude = position.coords.longitude
        console.log("GPS Latitude: " + driverGPSLatitude)
        console.log("GPS Longitude: " + driverGPSLongitude)
        setGPSLatitude(driverGPSLatitude)
        setGPSLongitude(driverGPSLongitude)
    }

    const watchID = geo.watchPosition(driverGPSCoordinates)
    function driverGPSCoordinates(position) {
        let driverGPSLatitude = position.coords.latitude
        let driverGPSLongitude = position.coords.longitude
        console.log("GPS Latitude: " + driverGPSLatitude)
        console.log("GPS Longitude: " + driverGPSLongitude)
        setGPSLatitude(driverGPSLatitude)
        setGPSLongitude(driverGPSLongitude)
    }

    const stopGPS = () => { 
        geo.clearWatch(watchID)
    }

  return (
      <>
          <h1>Driver Current Location</h1>
          <h2>Latitude : {latitude }</h2>
          <h2>Longitude : {longitude}</h2>
          <h2>Address : {address}</h2>
          <button type='button' className='btn' onClick={handleGetDriverAddress}>Get Driver Address</button>
          <hr />
          <h1>GPS Tracking</h1>
          <h2>GPS Latitude : {gps_latitude}</h2>
          <h2>GPS Longitude : {gps_longitude}</h2>
          <button type='button' className='btn' onClick={stopGPS}>Stop GPS</button>
    </>
  )
}

export default Location