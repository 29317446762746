import React from 'react'

const DriverView = (props) => {
    return (
        <div className='driver-card-container'>
            {props.children}
        </div>
    )
}

export default DriverView