import { useContext } from "react";
import styled from "styled-components";
import Menu from "./Menu";
import { useNavigate, Link } from "react-router-dom";
import useLogout from "../../../../hooks/useLogout";

import AuthContext from "../../../../context/AuthProvider";

const Navbar = ({ toggleDrawer, routes }) => {
  const { auth } = useContext(AuthContext)

  const navigate = useNavigate();
  const logout = useLogout();

  const logOut = async () => {
    await logout();
    navigate('/');
  }


  return (
    <SNavbar>
      {/* <DrawerButton onClick={toggleDrawer} >
        <i className={toggleDrawer ? "fas fa-bars" : "fas fa-times"} ></i>
      </DrawerButton> */}
      <NavContainer >
        <div className="left-navbar">
          <div className="company-logo">
            <img src="/images/logo/crysec.png" alt="Proforum" />
          </div>
          <div className="company-name">
            <h2>CRYSEC SERVICES</h2>
          </div>

          <div className="toggle-bar">
            <a className="sidebar-toggle" href="#" role="button" data-toggle="offcanvas">
              <i className="fas fa-bars"></i>
            </a>
          </div>
        </div>

        <nav className="navbar navbar-static-top" role="navigation">
          <div className="sidebar-toggle" href="#" role="button" data-toggle="offcanvas">
            {/* <span className="sr-only"></span> */}
            {/* <span className="icon-bar"></span> */}
            <span className="icon-bar"></span>&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
          <div className="navbar-custom-menu">
            <ul className="nav navbar-nav">
              <li className="dropdown user user-menu">
                <a className="dropdown-toggle" href="/dashboard" data-toggle="dropdown">
                  <span className="hidden-xs">
                    <span><i className="fas fa-user"></i> {auth.user} ({auth.user_role})</span><span></span>
                  </span>
                </a>
              </li>
              <li className="user-footer">
                <Link to='/logout' className="logout" data-method="delete" data-placement="bottom" data-toggle="tooltip" onClick={logOut} rel="nofollow" title="Logout">
                  <span className="hidden-xs"><i className="fa fa-sign-out"></i> Logout</span>
                </Link>
              </li>
            </ul>
          </div>
        </nav>

      </NavContainer >
    </SNavbar >
  );
};

export default Navbar;

const DrawerButton = styled.div`
 display: none;
 @media screen and (max-width: 768px){
   display: block;
    position: absolute;
    top: 60px;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 4rem;
    cursor: pointer;
    width: 30px;
    height: 10px;
    color: var(--white);
  }
`;

const SNavbar = styled.nav`
  background-color: var(--main-color);
`;

const NavContainer = styled.div`
  margin-right: 20px;
  padding: 1rem;
  height: 130px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  color: var(--white);
  background-color:var(--main-color);
  
  @media screen and (max-width: 768px) {
    align-items: flex-start;  
  }
`;

// const LeftNav = styled.div`
//   display: flex;
//   // align-items: flex-start;
//   // justify-content: flex-start;
//   gap: 2rem;
//   // max-width: 300px
// `;

const RightNav = styled.div`
  display: flex;
  gap: 2rem;
`;

const NavRoutes = styled.div`
 
@media screen and (max-width: 768px) {
    display: none;
  }

  display: flex;
  justify-content: flex-start;
  gap: 2rem;
  font-size: 2rem;
  align-items: center;
`;

// NAVBAR WITHOUT DROPDOWN
const NavRoute = styled(Link)`
  text-decoration: none;
  color: var(--white);
  padding: 0.5rem;
  font-size: 20px;
  font-weight: bold;
  border-radius: 5px;
  transition: all 0.5s ease;

  &:hover {
    transition: all 0.2s ease-out;
    color: var(--main-color);
    background-color: var(--white);
    box-shadow: 0px 0px 10px white;
  }

 @media screen and (max-width: 1200px) {
     font-size: 16px;
     font-weight: bold;
     white-space: nowrap;
  }
`;

