import React from 'react'

const Footer = () => {
    return (
        <>
            <footer className="main-footer">
                <div className="pull-right hidden-xs">
                    <b>Version</b> 2023.001
                </div>
                Copyright &copy; 2023 <strong style={{ fontSize: "16px", color: "var(--yellow)"}}>Driver Tracker</strong>. All rights reserved.
            </footer>
            <div className="control-sidebar-bg"></div>
        </>

    )
}

export default Footer

