import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { setAdminPassword } from '../../../api/apiServices';
import './Login.css';

const PasswordSetup = (user) => {
    const fullname = decodeURI(user.user[1]) + ' ' + decodeURI(user.user[3]) + ' ' + decodeURI(user.user[2])
    const userEmail = user.user[4];

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/login";

    const userRef = useRef();
    const errRef = useRef();

    const [name, setName] = useState(fullname);
    const email = userEmail;


    const [password, setPassword] = useState('');
    const [validPassword, setValidPassword] = useState(false);
    const [passwordFocus, setPasswordFocus] = useState(false);


    const [confirmPassword, setConfirmPassword] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [errMsg, setErrMsg] = useState('');

    const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

    useEffect(() => {
        userRef.current.focus();
    }, [])

    const v2 = PWD_REGEX.test(password);

    useEffect(() => {
        setValidPassword(v2);
        setValidMatch(password === confirmPassword);
    }, [password, confirmPassword, v2])

    useEffect(() => {
        setErrMsg('');
    }, [email, password, confirmPassword])

    const handleCancel = () => {
        navigate(from, { replace: true });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            email: email,
            password: password
        }

        if (!v2) {
            setErrMsg("Invalid Entry");
            return;
        }

        if (password === confirmPassword) {
            // try {
            setAdminPassword(data).then((response) => {
                if (response) {
                    confirmAlert({
                        customUI: ({ onClose }) => {
                            return (
                                <div className='confirm-container'>
                                    <h3>Password Setup</h3>
                                    <p>You have successfully setup your password</p>
                                    <p>You can log in with your username and password to access the system resources</p>
                                    <br />
                                    <div className='confirm-setup-buttons'>
                                        <button
                                            style={{ width: "100px" }}
                                            className="btn btn-success"
                                            onClick={(e) => { handleCancel(e); onClose(); }}
                                        >
                                            Ok
                                        </button><br />
                                    </div>
                                </div>
                            );
                        }
                    })
                } else {
                    console.log("failure")
                }
            })
            // } catch (err) {
            //     if (!err?.response) {
            //         setErrMsg('No Server Response');
            //     } else if (err.response?.status === 400) {
            //         setErrMsg('Missing username or password');
            //     } else if (err.response?.status === 401) {
            //         setErrMsg('Unauthorized');
            //     } else {
            //         console.log(err)
            //         setErrMsg('Login Failed');
            //     }
            //     errRef.current.focus();
            // }
        } else {
            setErrMsg('Passwords do not match. Try again !')
        }
    }

    return (
        <div className="full-screen-container">
            <div className="login-container">
                <h1 className="login-title">Password Setup</h1>
                <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>            <div className="login-box">
                    <form className="form" onSubmit={handleSubmit}>
                        <div className="form-group row">
                            <div className="col-sm-12">
                                <input
                                    type="text"
                                    id="name"
                                    placeholder='Name'
                                    autoComplete="off"
                                    style={{ fontWeight: 'bold' }}
                                    onChange={(e) => setName(e.target.value)}
                                    value={name}
                                    readOnly
                                    disabled
                                    className='form-control'
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-12">
                                <label htmlFor="password">
                                    <div className={validPassword ? "valid" : "hide"}>Password <i className="fas fa-check"></i></div>
                                    <div className={validPassword || !password ? "hide" : "invalid"}>Password <i className="fas fa-times"></i></div>
                                </label>
                                <input
                                    type="password"
                                    id="pwd"
                                    ref={userRef}
                                    placeholder='Password'
                                    autoComplete="off"
                                    onChange={(e) => setPassword(e.target.value)}
                                    value={password}
                                    required
                                    aria-invalid={validPassword ? "false" : "true"}
                                    aria-describedby="pwdnote"
                                    onFocus={() => setPasswordFocus(true)}
                                    onBlur={() => setPasswordFocus(false)}
                                    className='form-control'
                                />
                                <p id="pwdnote" className={passwordFocus && !validPassword ? "instructions" : "offscreen"}>
                                    <i className="fas fa-info-circle"></i> 8 to 24 characters.<br />
                                    Must include uppercase and lowercase letters, a number and a special character.<br />
                                    Allowed special characters: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                                </p>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-12">
                                <label htmlFor="confirm_pwd">
                                    <div className={validMatch && confirmPassword ? "valid" : "hide"}> Confirm Password <i className="fas fa-check"></i></div>
                                    <div className={validMatch || !confirmPassword ? "hide" : "invalid"}> Confirm Password <i className="fas fa-times"></i></div>
                                </label>
                                <div className="input-group success">
                                    <input
                                        type="password"
                                        id="confirm_password"
                                        placeholder='Confirm Password'
                                        autoComplete="off"
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        value={confirmPassword}
                                        required
                                        aria-invalid={validMatch ? "false" : "true"}
                                        aria-describedby="confirmnote"
                                        onFocus={() => setMatchFocus(true)}
                                        onBlur={() => setMatchFocus(false)}
                                        className='form-control'
                                    />
                                </div>
                                <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                                    <i className="fas fa-info-circle"></i> Must match the first password input field.
                                </p>
                            </div>
                        </div>
                        <div className="form-group row">
                           
                                <button
                                    disabled={!validPassword || !validMatch ? true : false}
                                    className='btn btn-success btn-block'>Submit</button>
                       

                            <button
                                type='button'
                                className='btn btn-danger btn-block'
                                onClick={handleCancel}>Cancel</button>

                       
                        </div>
                    </form>
                </div>
            </div >
        </div >
    )
}

export default PasswordSetup;
