export const SuperAdminSidebarData = [
    {
        title: 'Dashboard',
        path: '/dashboard',
    },
    {
        title: 'Driver Management',
        subNav: [
            {
                title: 'Registration',
                path: '/#',
                subNav: [
                    {
                        title: 'Register Driver',
                        path: '/admin_registration',
                    },
                    {
                        title: 'Driver Updates',
                        path: '/driver_updates',
                    },
                ],
            },
            {
                title: 'Enquiry',
                path: '/#',
                subNav: [
                    {
                        title: 'Glober Drivers',
                        path: '/global_drivers',
                    },
                ],
            },
        ]
    },
    {
        title: 'Payments Management',
        path: '/#',
        subNav: [
            {
                title: 'Receive Payment',
                path: '/receive_payments',
            },
            {
                title: 'Clearance',
                path: '/#',
                subNav: [
                    {
                        title: 'Capture MoMo',
                        path: '/capture_momo',
                    },
                    {
                        title: 'Payment Clearance',
                        path: '/payment_clearance',
                    },
                ],
            },
            {
                title: 'Payments',
                path: '/#',
                subNav: [
                    {
                        title: 'Registration Fees',
                        path: '/registration_fees',
                    },
                    {
                        title: 'Subscriptions',
                        path: '/subscriptions',
                    },
                    {
                        title: 'Device Payments',
                        path: '/device_payments',
                    },
                    {
                        title: 'Payment Transactions',
                        path: '/payment_transactions',
                    },
                ],
            },
            {
                title: 'Ledgers',
                path: '/#',
                subNav: [
                    {
                        title: 'Ledger Accounts',
                        path: '/ledger_accounts',
                    },
                    {
                        title: 'Yearly Ledger',
                        path: '/yearly_ledger',
                    },
                ],
            },
            {
                title: 'Expenditure',
                path: '/#',
                subNav: [
                    {
                        title: 'Expenditure Items',
                        path: '/expenditure',
                    },
                ],
            },
            {
                title: 'Revenue',
                path: '/#',
                subNav: [
                    {
                        title: 'Revenue Items',
                        path: '/revenue',
                    },
                ],
            },
            {
                title: 'Setup',
                path: '/#',
                subNav: [
                    {
                        title: 'Payment Types',
                        path: '/payment_types',
                    },
                    {
                        title: 'Payment List',
                        path: '/payment_list',
                    },
                ],
            },

        ]
    },
    {
        title: 'Responses Management',
        path: '/#',
        subNav: [
            {
                title: 'Response Request',
                path: '/response_request',
            },
            {
                title: 'Response Teams',
                path: '/response_teams',
            },
        ]
    },
    {
        title: 'Contact Management',
        subNav: [
            {
                title: 'Contact Messages',
                path: '/contact_messages',
            },
            {
                title: 'Contact Status',
                path: '/contact_status',
            },
        ]
    },
    {
        title: 'System Admin',
        subNav: [
            {
                title: 'System Setup',
                subNav: [
                    {
                        title: 'System Users',
                        path: '/system_users',
                    },
                    {
                        title: 'System Roles',
                        path: '/system_roles',
                    },
                ],
            },
            {
                title: 'General Setup',
                path: '/general_setup',
            },
        ]
    },
    {
        title: 'Logout',
        path: '/logout',
    },
];

