import { useState } from "react";

const Checkbox = ({ label, checked, handleAgreement, ...props }) => {
    console.log(checked+" "+props)
    const defaultChecked = checked ? checked : false;
    const [isChecked, setIsChecked] = useState(defaultChecked);

    return (
        <div className="checkbox-wrapper">
            <label>
                <input
                    type="checkbox"
                    checked={isChecked}
                    name='agreeTerms'
                    value={isChecked}
                    onChange={(e) => { setIsChecked((prev) => !prev); handleAgreement(e) }}
                    className={isChecked ? "checked" : ""}
                    {...props}
                />
                <p>{label}</p>
            </label>
            {/* <p>{isChecked ? "Selected" : "Unchecked"}</p> */}
        </div>
    );
};
export default Checkbox;
