import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
	addPaymentList,
	getPaymentTypes,
	getPaymentTypeByCode,
	getPreviousPaymentList
} from "../../../../../api/apiServices";
import toast, { Toaster } from 'react-hot-toast'

const AddPaymentList = () => {

	const navigate = useNavigate();
	const location = useLocation();
	const from = location.state?.from?.pathname || "/payment_list";

	const [payment_year, setPaymentYear] = useState("");
	const [type_code, setTypeCode] = useState("");
	const [description, setDescription] = useState("");
	const [amount, setAmount] = useState("");

	const year = (new Date()).getFullYear() - 5;
	const years = Array.from(new Array(20), (val, index) => index + year)

	const queryClient = useQueryClient();
	const {
		data: payment_types,
	} = useQuery('payment_types', getPaymentTypes);

	const addPaymentListMutation = useMutation(addPaymentList, {
		onSuccess: async (res) => {
			// Invalidate cached data
			queryClient.invalidateQueries("paymentList")
			toast.success(res.message, {
				duration: 5000,
				className: 'toast-message'
			})
			setPaymentYear("")
			setTypeCode("")
			setDescription("")
			setAmount("")
			navigate(from, { replace: true });
		},
		onError: async (err) => {
			toast.error(err.message, {
				duration: 5000,
				className: 'toast-message'
			})
		}
	})

	const handleTypeChange = (e) => {
		const typeCode = e.target.value
		setTypeCode(typeCode)
		if (typeCode === "RF") {
			getPaymentTypeByCode(typeCode).then(res => {
				setDescription(res.description)
			})
		}
		if (typeCode === "SS") {
			getPaymentTypeByCode(typeCode).then(res => {
				setDescription(res.description)
			})
		}
		if (typeCode === "DP") {
			getPaymentTypeByCode(typeCode).then(res => {
				setDescription(res.description)
			})
		}
	}

	const handleSameAsPreviousYear = () => {
		const previousYear = payment_year
		getPreviousPaymentList(previousYear)
		navigate(from, { replace: true });
	}

	const handleSubmit = (e) => {
		e.preventDefault()

		const dataValues = {
			year: year,
			type_code: type_code.toUpperCase(),
			description: description.toUpperCase(),
			amount: parseFloat(amount).toFixed(2),
		}
		addPaymentListMutation.mutate(dataValues)
	}
	const handleCancel = () => {
		navigate(from, { replace: true });
	}

	const content = (
		<>
			<form onSubmit={handleSubmit}>
				<div className="panel-body">
					<div className="form-group row">
						<label htmlFor="payment_year" className="col-sm-2 col-form-label">
							Payment Year
						</label>
						<div className="col-sm-6">
							<select
								type="text"
								id="payment_year"
								placeholder='Payment Year'
								autoComplete="off"
								style={{ textTransform: "capitalize" }}
								onChange={(e) => setPaymentYear(e.target.value)}
								required
								value={payment_year}
								name="payment_year"
								className="form-control"
							>
								<option>Select Year</option>
								{years.map((year, index) => <option value={year} key={`year${index}`}>{year}</option>
								)}
							</select>
						</div>
					</div>
					<div className="form-group row">
						<label htmlFor="type_code" className="col-sm-2 col-form-label">
							Payment type
						</label>
						<div className="col-sm-6">
							<select
								type="text"
								id="payment_code"
								autoComplete="off"
								style={{ textTransform: "uppercase" }}
								value={type_code}
								onChange={handleTypeChange}
								required
								name="type_code"
								className="form-control"
							>
								<option>Select Option</option>
								{payment_types?.map((type, index) =>
									<option value={type.type_code} key={type.id}>
										{type.type_code} - {type.description}
									</option>
								)}
							</select>
						</div>
					</div>
					<div className="form-group row">
						<label htmlFor="description" className="col-sm-2 col-form-label">
							Payment Description
						</label>
						<div className="col-sm-6">
							<input
								type="text"
								id="description"
								autoComplete="off"
								value={description}
								disabled
								style={{ textTransform: "uppercase" }}
								name="description"
								className="form-control"
							/>
						</div>
					</div>
					<div className="form-group row">
						<label htmlFor="amount" className="col-sm-2 col-form-label">
							Payment Amount
						</label>
						<div className="col-sm-6">
							<input
								type="number"
								id="amount"
								autoComplete="off"
								value={amount}
								onChange={(e) => setAmount(e.target.value)}
								required
								style={{ textTransform: "uppercase" }}
								name="amount"
								className="form-control"
							/>
						</div>
					</div>
					<div className="panel-footer">
						<div className="form-group row">
							<div className="col-sm-1">
								<button type="submit" className="btn btn-success  btn-block">
									Submit
								</button>
							</div>&nbsp;&nbsp;&nbsp;&nbsp;
							<div className="col-sm-2">
								<button onClick={handleSameAsPreviousYear} className="btn btn-warning  btn-block">
									Same As Previous Year
								</button>
							</div>&nbsp;&nbsp;&nbsp;&nbsp;
							<div className="col-sm-1">
								<button type="submit" className="btn btn-danger btn-block" onClick={handleCancel}>
									Cancel
								</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		</>
	)

	return (
		<>
			<section className="content-header">
				<Toaster toastOptions={{ position: "top-center" }} />
				<h1>Add Payment List</h1>
			</section>
			<section className="content">
				<div className="box">
					<div className="box-body">
						<div className="panel panel-default panel-br-4">
							<div className="panel-heading">
								<div className="row">
									<div className="col-sm-12">
										<h4 className="panel-title">
											<i className="fa fa-file-text" aria-hidden="true"></i>
											<span> Payment List Setup</span>
										</h4>
									</div>
								</div>
							</div>
							<div className="panel-body">
								{content}
							</div>
						</div>
					</div>
					<div className="box-footer"></div>
				</div>
			</section>
		</>
	)
}

export default AddPaymentList