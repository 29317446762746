import { useState, useRef, useEffect } from "react";
import useRegistrationContext from "../../../hooks/useRegistrationContext"
import {
    getNationality,
    getIdentification,
} from "../../../api/apiServices";

const PersonalInformation = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    
    const inputRef = useRef();
    const [nationality, setNationality] = useState([]);
    const [identification, setIdentification] = useState([]);

    useEffect(() => {
        getNationality().then((res) => setNationality(res));
        getIdentification().then((res) => setIdentification(res));
        inputRef.current.focus();
    }, [])

    const {
        data,
        handleChange,
        handleExpiryDate,
        handlePhoto
    } = useRegistrationContext()

    const [image, setImage] = useState();

    function handleImage(e) {
        if (e.target.files.length !== 0) {
            setImage(URL.createObjectURL(e.target.files[0]));
        }
    }

    const content = (
        <>
            {image ?
                <div className="driver-photo">
                    <img src={image} alt='' />
                </div>
                : null}
            <div className="form-group row">
                <fieldset className="col-sm-6">
                    <div className="form-group row">
                        <label htmlFor="photo" className="col-sm-3 col-form-label">Driver Photo</label>
                        <div className="col-sm-9">
                            <input
                                type="file"
                                name="persPhoto"
                                id="photo"
                                autoComplete="off"
                                style={{
                                    height: "40px",
                                }}
                                onBlur={handleImage}
                                onChange={handlePhoto}
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="first_name" className="col-sm-3 col-form-label">First Name<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <input
                                ref={inputRef}
                                type="text"
                                placeholder="First Name"
                                id="first_name"
                                autoComplete="off"
                                style={{
                                    height: "40px",
                                    textTransform: "uppercase",
                                }}
                                onChange={handleChange}
                                required
                                value={data.persFirstname}
                                name="persFirstname"
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="last_name" className="col-sm-3 col-form-label">Last Name<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <input
                                type="text"
                                placeholder="Last Name"
                                id="last_name"
                                autoComplete="off"
                                onChange={handleChange}
                                required
                                style={{
                                    height: "40px",
                                    textTransform: "uppercase",
                                }}
                                value={data.persLastname}
                                name="persLastname"
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="gender" className="col-sm-3 col-form-label">Gender<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <select
                                type="text"
                                id="gender"
                                autoComplete="off"
                                onChange={handleChange}
                                required
                                style={{
                                    height: "40px",
                                    textTransform: "uppercase",
                                }}
                                value={data.persGender}
                                name="persGender"
                                className='form-control'
                            >
                                <option value="">Select Option</option>
                                <option value="Male">Male</option>
                                <option value='Female'>Female</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="date_of_birth" className="col-sm-3 col-form-label">Date of Birth<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <input
                                type="date"
                                id="date_of_birth"
                                autoComplete="off"
                                onChange={handleChange}
                                style={{
                                    height: "40px",
                                    textTransform: "uppercase",
                                }}
                                required
                                value={data.persDOB}
                                name="persDOB"
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="nationality" className="col-sm-3 col-form-label">Nationality<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <select
                                type="text"
                                id="nationality"
                                autoComplete="off"
                                style={{
                                    height: "40px",
                                    textTransform: "uppercase",
                                }}
                                onChange={handleChange}
                                value={data.persNationality}
                                required
                                name="persNationality"
                                className='form-control'
                            >
                                <option>Select Option</option>
                                {nationality?.map(item =>
                                    <option key={item.id} value={item.nationality}>{item.nationality}</option>
                                )}
                            </select>
                        </div>
                    </div>
                </fieldset>
                <fieldset className="col-sm-6">
                    <div className="form-group row">
                        <label htmlFor="identification_type" className="col-sm-3 col-form-label">Identification Type<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <select
                                type="text"
                                id="identification_type"
                                autoComplete="off"
                                onChange={handleChange}
                                required
                                style={{
                                    height: "40px",
                                    textTransform: "uppercase",
                                }}
                                value={data.persIdentificationType}
                                name="persIdentificationType"
                                className='form-control'
                            >
                                <option>Select Option</option>
                                {identification?.map(item =>
                                    <option key={item.id} value={item.identification}>{item.identification}</option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="id_number" className="col-sm-3 col-form-label">ID Number<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <input
                                type="text"
                                id="id_number"
                                placeholder='ID Number'
                                autoComplete="off"
                                style={{
                                    height: "40px",
                                    textTransform: "uppercase",
                                }}
                                onChange={handleChange}
                                required
                                value={data.persIdNumber}
                                name="persIdNumber"
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="date_issued" className="col-sm-3 col-form-label">Date Issued<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <input
                                type="date"
                                id="date_issued"
                                autoComplete="off"
                                style={{
                                    height: "40px",
                                    textTransform: "uppercase",
                                }}
                                required
                                onChange={handleExpiryDate}
                                value={data.persDateIssued}
                                name="persDateIssued"
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="expiry_date" className="col-sm-3 col-form-label">Expiry Date<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <input
                                type="date"
                                id="expiry_date"
                                autoComplete="off"
                                onChange={handleChange}
                                required
                                style={{
                                    height: "40px",
                                    textTransform: "uppercase",
                                }}
                                disabled
                                value={data.persExpiryDate}
                                name="persExpiryDate"
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="marital_status" className="col-sm-3 col-form-label">Marital Status<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <select
                                type="text"
                                id="marital_status"
                                autoComplete="off"
                                onChange={handleChange}
                                required
                                value={data.persMaritalStatus}
                                style={{ textTransform: "uppercase" }}
                                name="persMaritalStatus"
                                className='form-control'
                            >
                                <option value="">Select Option</option>
                                <option value="Single">Single</option>
                                <option value='Married'>Married</option>
                                <option value='Divorced'>Divorced</option>
                                <option value='Separated'>Separated</option>
                                <option value='Widowed'>Widowed</option>
                                <option value='Prefer Not to Say'>Prefer Not to Say</option>
                            </select>
                        </div>
                    </div>
                </fieldset>
            </div>
        </>
    )
    return content
}
export default PersonalInformation