import React from 'react';
import FormInputs from './FormInputs'
import useRegistrationContext from "../../../hooks/useRegistrationContext"
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useMutation } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    addDriver,
} from "../../../api/apiServices";


import toast, { Toaster } from 'react-hot-toast'

const FormSetup = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/main_payments";

    const {
        page,
        setPage,
        data,
        title,
        canSubmit,
        disablePrev,
        disableNext,
        prevHide,
        nextHide,
        submitHide
    } = useRegistrationContext()

    const addDriverMutation = useMutation(addDriver, {
        onSuccess: async (res) => {
            if (res.status === false) {
                toast.error(res.message, {
                    duration: 5000,
                    className: "toast-message",
                });
                return false
            } else {
                toast.success(res.message, {
                    duration: 20000,
                    className: "toast-message",
                });
                navigate(from, { replace: true });
            }
        },
        onError: async (err) => {
            toast.error(err.message, {
                duration: 5000,
                className: "toast-message",
            });
            return false
        }
    })


    const handlePrevious = () => setPage(prev => prev - 1)

    const handleNext = () => setPage(prev => prev + 1)

    const handleSubmit = async () => {

        let formData = new FormData();
        formData.append('file', data.persPhoto)
        formData.set("last_name", data.persLastname);
        formData.set("first_name", data.persFirstname);
        formData.set("gender", data.persGender);
        formData.set("date_of_birth", data.persDOB);
        formData.set("nationality", data.persNationality);
        formData.set("identification_type", data.persIdentificationType);
        formData.set("id_number", data.persIdNumber);
        formData.set("date_issued", data.persDateIssued);
        formData.set("expiry_date", data.persExpiryDate);
        formData.set("marital_status", data.persMaritalStatus);

        formData.set("car_number", data.contactCarNumber);
        formData.set("mobile_number", data.contactMobileNumber);
        formData.set("email", data.contactEmail);
        formData.set("spouse_mobile_number", data.contactSpouseMobileNumber);
        formData.set("gps_address", data.contactGpsAddress);

        formData.set("next_of_kin", data.nkinNextOfKin);
        formData.set("kin_mobile_number", data.nkinMobileNumber);
        formData.set("kin_relationship", data.nkinRelationship);

        formData.set("reference_person", data.refpReferencePerson);
        formData.set("reference_mobile_number", data.refpReferenceMobileNumber);

        addDriverMutation.mutate(formData)
    }

    const mainForm = (
        <form onSubmit={handleSubmit}>
            <FormInputs />
            <div className="panel-footer">
                <button
                    type="button"
                    className={`btn btn-info  button ${prevHide}`} onClick={handlePrevious}
                    disabled={disablePrev}>
                    Previous
                </button>&nbsp;&nbsp;&nbsp;&nbsp;
                <button
                    type="button"
                    className={`btn btn-success button ${nextHide}`}
                    onClick={handleNext}
                    disabled={disableNext}>
                    Next
                </button>&nbsp;&nbsp;&nbsp;&nbsp;
                <button
                    type="submit"
                    className={`btn btn-success button ${submitHide}` }
                    disabled={!canSubmit}>
                    Submit
                </button>
            </div>

        </form >
    )

    const content = (
        <>
            <section className="content-header">
                <Toaster toastOptions={{ position: "top-center" }} />
                <h1><i className="fas fa-users"></i> Registration</h1>
            </section>
            <section className="content">
                <div className="box">
                    <div className="box-body">
                        <div className="panel panel-default panel-br-4">
                            <div className="panel-heading">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h4 className="panel-title">
                                            {title[page]}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-body">
                                <div className="wrapper-container">
                                    {mainForm}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
    return content
}
export default FormSetup