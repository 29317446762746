export const routes = [
  {
    name: "HOME",
    link: "/",
  },
  {
    name: 'ABOUT US',
    link: '/about',
  },
  {
    name: 'SERVICES',
    link: '/services',
  },
  {
    name: "REGISTRATION",
    link: "/main_registration",
  },
  {
    name: "PAYMENTS",
    link: "/main_payments",
  },
  {
    name: "CONTACT US",
    link: "/contact",
  },
];
