import "./App.css"
import "./Index.css"
import React from "react"
import { Routes, Route, Navigate } from 'react-router-dom';

import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from './components/admin/ErrorFallback/ErrorFallback';
import SkeletonAdmin from './components/admin/skeletons/SkeletonAdmin';
import { lazy, Suspense } from 'react';

import Home from "./components/main/home/Home"
import About from "./components/main/about/About"
import Pricing from "./components/main/pricing/Pricing"
import Blog from "./components/main/blog/Blog"
import Services from "./components/main/services/Services"
import Contact from "./components/main/contact/Contact"
import MainLayout from "./components/common/layout/MainLayout";

import Login from "./components/admin/login/Login";
import PasswordSetup from "./components/admin/login/PasswordSetup";
import Unauthorized from "./components/common/unauthorized/Unauthorized";
import Logout from "./components/admin/logout/Logout";

import UserRoles from './hooks/userRoles';
import PersistLogin from './components/admin/persistLogin/PersistLogin';
import RequireAuth from './components/admin/requireAuth/RequireAuth';

import Map from './components/admin/dashboard/Map';

import AdminRegistration from './components/admin/registration/Registration';
import MainRegistration from './components/main/registration/Registration';

import MainPayments from "./components/main/payment/MainPayments";
import AdminPayments from "./components/admin/Accounts/Payments/AdminPayments";

import AddPaymentType from "./components/admin/Accounts/Payments/PaymentTypes/AddPaymentType";
import EditPaymentType from "./components/admin/Accounts/Payments/PaymentTypes/EditPaymentType";
import ViewPaymentType from "./components/admin/Accounts/Payments/PaymentTypes/ViewPaymentType";

import Layout from './components/common/layout/Layout';
import Location from "./components/admin/location/Location";
import MoreAbout from "./components/main/about/MoreAbout";
import MainLogin from "./components/main/login/MainLogin";
import MemPersistLogin from "./components/admin/persistLogin/MemPersistLogin";
import MemberLayout from "./components/common/layout/MemberLayout";
import RequireMember from "./components/admin/requireAuth/RequireMember";
import TermsConditions from "./components/main/terms/TermsConditions";
import TermsConditions1 from "./components/main/registration/TermsConditions";

import DriverDisplay from "./components/admin/drivers/driver/DisplayDriver";
import CaptureMomo from "./components/admin/Accounts/Payments/CaptureMomo";
import PaymentList from "./components/admin/Accounts/Payments/PaymentList/PaymentList";
import AddPaymentList from "./components/admin/Accounts/Payments/PaymentList/AddPaymentList";
import WhatsappMsg from "./components/main/whatsapp/WhatsappMsg";
import PaymentInfo from "./components/main/registration/PaymentInfo";

import RegistrationFees from "./components/admin/Accounts/Payments/RegistrationFees/RegistrationFees";
import Subscriptions from "./components/admin/Accounts/Payments/Subscriptions/Subscriptions.js";
import DevicePayments from "./components/admin/Accounts/Payments/DevicePayments/DevicePayments.js";

const AdminDashboard = lazy(() => import("./components/admin/gMap/AdminDashboard"));
const GlobalDrivers = lazy(() => import('./components/admin/drivers/GlobalDrivers'));
const ViewDriver = lazy(() => import('./components/admin/drivers/ViewDriver'));
const GeneralSetup = lazy(() => import('./components/admin/setup/general/GeneralSetup'));
const PaymentTypes = lazy(() => import('./components/admin/Accounts/Payments/PaymentTypes/PaymentTypes'));
const PaymentClearance = lazy(() => import('./components/admin/Accounts/Payments/PaymentClearance'));
const AdminUsers = lazy(() => import('./components/admin/users/AdminUsers'));
const PaymentTransactions = lazy(() => import("./components/admin/Accounts/Payments/PaymentTransactions"));

function App() {
  let userLogin = window.location.search.substring(6).split("&");

  return (
    <>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path="/set_password" element={< PasswordSetup user={userLogin} />} />
        <Route path="/unauthorized" element={< Unauthorized />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/location" element={<Location />} />

        {/* PUBLIC ROUTES */}
        <Route path="/" exact element={<MainLayout />}>
          <Route path='/' element={<Home />} />
          <Route path='/main_login' element={<MainLogin />} />
          <Route path='/main_registration' element={<MainRegistration />} />
          <Route path='/main_payments' element={<MainPayments />} />
          <Route path='/about' element={<About />} />
          <Route path='/more_about' element={<MoreAbout />} />
          <Route path='/services' element={<Services />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/pricing' element={<Pricing />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/terms_conditions' element={<TermsConditions />} />

          <Route path='/conditions' element={<TermsConditions1 />} />
          <Route path='/payment_info' element={<PaymentInfo />} />
          <Route path='/whatsaap' element={<WhatsappMsg />} />
        </Route>

        {/* MEMBER PRIVATE ROUTES*/}
        <Route path="/" exact element={<MemberLayout user={userLogin} />}>
          <Route element={<MemPersistLogin />}>
            <Route element={<RequireMember
              allowedRoles=
              {
                [
                  UserRoles.Member
                ]
              }
            />}>
              {/* <Route path='/membership_enquiry' exact element={< MemberEnquiry />} /> */}
              {/* <Route path='/member/dashboard' exact element={< MemberDashboard member={mem} />} />
              <Route path='/home' exact element={< Home member={mem} />} />
              <Route path='/mandatory_payment_details/:id' exact element={< MandatoryPayments data={key} />} />
              <Route path='/other_payment_details/:id' exact element={< OtherPayments data={key} />} />
              <Route path='/view_profile' exact element={< Profile member={mem} />} />
              <Route path='/update_profile' exact element={< UpdateProfile member={mem} />} />

              <Route path='/member_2m2_payments' exact element={< MemberTwoMTwoPayment member={mem} />} />
              <Route path='/member_regular_payments' exact element={< MemberRegularPayment member={mem} />} />
              <Route path='/member_ledger_account' exact element={< MemberLedger member={mem} />} />
              <Route path='/member_payment_transactions' exact element={< MemberPaymentTransactions member={mem} />} />
              <Route path='/member_statement' exact element={< MemberStatement member={mem} />} />
              <Route path='/pdf_service' exact element={< PdfServices member={mem} />} />

              <Route path='/contact_proforum' exact element={< ContactProforum member={mem} />} />
              <Route path='/change_member_password' exact element={< ChangePassword data={mem} />} /> */}
            </Route>
          </Route>
        </Route>


        {/* ADMIN ROUTES */}
        <Route path="/" exact element={<Layout />}>
          <Route element={<PersistLogin />}>
            <Route element={<RequireAuth
              allowedRoles={
                [
                  UserRoles.SuperAdmin,
                  UserRoles.SystemAdmin,
                  UserRoles.SuperUser
                ]
              }
            />}>
              <Route path="/dashboard" element={
                <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                  onReset={() => Navigate('/dashboard')}>
                  <Suspense fallback={<SkeletonAdmin />}>
                    <AdminDashboard />
                  </Suspense>
                </ErrorBoundary>}
              />
              <Route path='/tracker_map' exact element={< Map />} />

              {/* GENERAL SETUP */}
              <Route path="/general_setup" element={
                <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                  onReset={() => Navigate('/dashboard')}>
                  <Suspense fallback={<SkeletonAdmin />}>
                    <GeneralSetup />
                  </Suspense>
                </ErrorBoundary>}
              />

              {/* DRIVERS */}
              <Route path="/global_drivers" element={
                <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                  onReset={() => Navigate('/dashboard')}>
                  <Suspense fallback={<SkeletonAdmin />}>
                    <GlobalDrivers />
                  </Suspense>
                </ErrorBoundary>}
              />
              <Route path='/display_driver/:id' exact element={< DriverDisplay />} />
              <Route path="/view_driver/:id" element={
                <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                  onReset={() => Navigate('/dashboard')}>
                  <Suspense fallback={<SkeletonAdmin />}>
                    < ViewDriver />
                  </Suspense>
                </ErrorBoundary>}
              />

              {/* ADMIN USERS */}
              <Route path="/system_users" element={
                <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                  onReset={() => Navigate('/')}>
                  <Suspense fallback={<SkeletonAdmin />}>
                    <AdminUsers />
                  </Suspense>
                </ErrorBoundary>}
              />

              {/* PAYMENTS */}
              <Route path='/receive_payments' exact element={< AdminPayments />} />
              <Route path='/registration_fees' exact element={< RegistrationFees />} />
              <Route path='/subscriptions' exact element={< Subscriptions />} />
              <Route path='/device_payments' exact element={< DevicePayments />} />

              <Route path="/payment_transactions" element={
                <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                  onReset={() => Navigate('/dashboard')}>
                  <Suspense fallback={<SkeletonAdmin />}>
                    <PaymentTransactions />
                  </Suspense>
                </ErrorBoundary>}
              />


              {/* PAYMENT TYPES */}
              <Route path='/add_payment_type' exact element={< AddPaymentType />} />
              <Route path='/edit_payment_type/:id' exact element={< EditPaymentType />} />
              <Route path='/view_payment_type/:id' exact element={< ViewPaymentType />} />
              <Route path="/payment_types" element={
                <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                  onReset={() => Navigate('/dashboard')}>
                  <Suspense fallback={<SkeletonAdmin />}>
                    <PaymentTypes />
                  </Suspense>
                </ErrorBoundary>}
              />

              {/* PAYMENT LIST */}
              <Route path='/add_payment_list' exact element={< AddPaymentList />} />
              <Route path='/edit_payment_list/:id' exact element={< EditPaymentType />} />
              <Route path='/view_payment_list/:id' exact element={< ViewPaymentType />} />
              <Route path="/payment_list" element={
                <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                  onReset={() => Navigate('/dashboard')}>
                  <Suspense fallback={<SkeletonAdmin />}>
                    <PaymentList />
                  </Suspense>
                </ErrorBoundary>}
              />

              <Route path='/capture_momo' exact element={< CaptureMomo />} />
              <Route path="/payment_clearance" element={
                <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                  onReset={() => Navigate('/dashboard')}>
                  <Suspense fallback={<SkeletonAdmin />}>
                    <PaymentClearance />
                  </Suspense>
                </ErrorBoundary>}
              />

              {/* REGISTRATION */}
              <Route path='/admin_registration' exact element={< AdminRegistration />} />
              {/* SYSTEM USERS */}
            </Route>
          </Route>
        </Route>
      </Routes>
    </>
  )
}

export default App
