import React, { useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getPaymentTypeById } from "../../../../../api/apiServices";
import toast, { Toaster } from 'react-hot-toast'

const ViewPaymentType = () => {
	const params = useParams();
	const typeId = parseInt(params.id)

	const navigate = useNavigate();
	const location = useLocation();
	const from = location.state?.from?.pathname || "/payment_types";

	const [payment_code, setPaymentCode] = useState("");
	const [description, setDescription] = useState("");

	const {
		data: type,
		isLoading,
		isError,
		error } = useQuery(["type", typeId], () => getPaymentTypeById(typeId), {
			enabled: Boolean(typeId)
		});

	const handleCancel = () => {
		navigate(from, { replace: true });
	}

	let content
	if (!typeId) {
		content = <p>Select payment type</p>
	} else if (isLoading) {
		content = <p>Loading payment type details...</p>
	} else
		if (isError) {
			content = <p>{error.message}</p>
		} else
			content = (
				<>
					<div className="panel panel-success panel-br-4">
						<div className="panel-heading">
							<div className="row">
								<div className="col-sm-6">
									<h4 className="panel-title">
										<i className="fa fa-file-text" aria-hidden="true"></i>
										<span> View Payment Type</span>
									</h4>
								</div>
							</div>
						</div>
						<div className="panel-body">
							<form>
								<div className="form-group row">
									<label htmlFor="payment_code" className="col-sm-2 col-form-label">
										Payment Code
									</label>
									<div className="col-sm-6">
										<input
											type="text"
											id="payment_code"
											style={{ textTransform: "uppercase" }}
											value={type.payment_code}
											readOnly
											className="form-control"
										/>
									</div>
								</div>
								<div className="form-group row">
									<label htmlFor="description" className="col-sm-2 col-form-label">
										Description
									</label>
									<div className="col-sm-6">
										<input
											type="text"
											id="description"
											value={type.description}
											readOnly
											style={{ textTransform: "uppercase" }}
											className="form-control"
										/>
									</div>
								</div>
								<div className="panel-footer">
									<div className="form-group row">
										<div className="col-sm-1">
											<button type="submit" className="btn btn-danger" onClick={handleCancel}>
												Cancel
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</>
			)


	return (
		<>
			<section className="content-header">
				<Toaster toastOptions={{ position: "top-center" }} />
				<h1>Payment Type Details</h1>
			</section>
			<section className="content">
				<div className="box">
					<div className="box-body">
						<div className="panel panel-default panel-br-4">
							<div className="panel-heading">
								<div className="row">
									<div className="col-sm-12">
										<h4 className="panel-title">
										</h4>
									</div>
								</div>
							</div>
							<div className="panel-body">
								{content}
							</div>
						</div>
					</div>
					<div className="box-footer"></div>
				</div>
			</section>
		</>
	)
}

export default ViewPaymentType