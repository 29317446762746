import useAuth from './useAuth';
import { adminRefreshToken } from '../api/apiServices'

const useRefreshToken = () => {
    const { setAuth } = useAuth();

    const refresh = async () => {
        const response = await adminRefreshToken();
        setAuth(prev => {
            // console.log("Previous :", JSON.stringify(prev));
            // console.log("New Token :", response.accessToken);
            return {
                ...prev,
                user_role: response.user_role,
                fullname: response.fullname,
                user: response.user,
                email: response.email,
                accessToken: response.accessToken,
            }
        });
        return response.accessToken;
    }
    return refresh;
};

export default useRefreshToken;
