import { useEffect, useRef } from "react";
import useRegistrationContext from "../../../hooks/useRegistrationContext"
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

const ReferencePerson = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    const inputRef = useRef();
    const { data, handleOnPhoneInput, handleChange, handlePhoneInput } = useRegistrationContext()

    useEffect(() => {
        inputRef.current.focus();
    }, [])
    const content = (
        <>
            <div className="form-group row">
                <fieldset className="col-sm-6">
                    <div className="form-group row">
                        <label htmlFor="reference_person" className="col-sm-4 col-form-label">Reference Person</label>
                        <div className="col-sm-8">
                            <input
                                ref={inputRef}
                                type="text"
                                id="reference_person"
                                placeholder='Reference Person'
                                autoComplete="off"
                                style={{
                                    height: "40px",
                                    textTransform: "uppercase",
                                }}
                                onChange={handleChange}
                                required
                                value={data.refpReferencePerson}
                                name="refpReferencePerson"
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="reference_mobile_number" className="col-sm-4 col-form-label">Mobile Number</label>
                        <div className="col-sm-8">
                            <PhoneInput
                                type="tel"
                                id="reference_mobile_number"
                                inputStyle={{ width: '100%', height: "34px" }}
                                placeholder='refpReferenceMobileNumber'
                                country={"gh"}
                                countryCodeEditable={false}
                                enableSearch={true}
                                value={data.refpReferenceMobileNumber}
                                name="refpReferenceMobileNumber"
                                onChange={(e) => handlePhoneInput(e)}
                                onKeyDown={(e) => handleOnPhoneInput(e)}
                            />
                        </div>
                    </div>
                </fieldset>
            </div>
        </>
    )

    return content
}
export default ReferencePerson