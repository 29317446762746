import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import useMember from "../../../hooks/useMember";
import { jwtDecode } from "jwt-decode";

const RequireMember = ({ allowedRoles }) => {
    const { mem } = useMember();
    const location = useLocation();

    const decoded = mem?.accessToken
        ? jwtDecode(mem.accessToken)
        : undefined

    const role = decoded?.UserInfo?.role || ""
    
    return (
        allowedRoles?.includes(role)
            ? <Outlet />
            : mem?.user
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/" state={{ from: location }} replace />
    );
}

export default RequireMember;