export const routes = [
  {
    name: "Home",
    link: "/dashboard",
  },
  {
    name: "Drivers",
    link: "/#",
    subRoutes: [
      {
        name: 'Global Drivers',
        link: '/global_drivers',
      },
      {
        name: 'Registration',
        link: '/admin_registration',
      },
      {
        name: 'Driver Update',
        link: '/update',
      },
      {
        name: 'Driver Status',
        link: '/status',
      },
      {
        name: 'Driver Location',
        link: '/location',
      },
    ],
  },
  {
    name: "Payments",
    link: "/#",

    subRoutes: [
      {
        name: 'Receive Payment',
        link: '/receive_payments',
      },
      {
        name: 'Payment Clearance',
        link: '/payment_clearance',
      },
      {
        name: 'Payment Transactions',
        link: '/payment_transactions',
      },
      {
        name: 'Ledger Accounts',
        link: '/ledger_accounts',
      },
      {
        name: 'Payment Types',
        link: '/payment_types',
      },
    ]
  },
  {
    name: 'Gallery',
    link: '/gallery',
  },
  {
    name: "Setup",
    link: "/#",

    subRoutes: [
      {
        name: 'User Setup',
        link: '/user_setup',
      },
      {
        name: 'General Setup',
        link: '/general_setup',
      },
    ]
  },
  {
    name: 'Logout',
    link: '/logout',
  },
];
