import React, { useEffect, useRef, useState } from 'react'
import { useMutation } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast'
import {
    getPaymentTypes,
    findDriverByCarNumber,
    checkRegFeePayment,
    addPayment,
} from '../../../../api/apiServices';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

const AdminPayments = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const inputRef = useRef();

    useEffect(() => {
        inputRef.current.focus();
    }, [])

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/main_payments";

    const [mobile_number, setMobileNumber] = useState("")
    const [country, setCountry] = useState("gh");

    const [isSuscription, setIsSubscription] = useState(false)
    const [payment_year, setPaymentYear] = useState("")
    const [payment_month, setPaymentMonth] = useState("")
    const [payment_types, setPaymentTypes] = useState([])
    const [payment_type, setPaymentType] = useState("")
    const [driver_id, setDriverId] = useState("")
    const [car_number, setCarNumber] = useState("")
    const [new_driver_code, setNewDriverCode] = useState("")
    const [driver_code, setDriverCode] = useState("")
    const [fullname, setFullname] = useState("")
    const [amount, setAmount] = useState("")
    const [transaction_id, setTransactionId] = useState("")

    const year = 2024;
    const years = Array.from(new Array(20), (val, index) => index + year)

    useEffect(() => {
        getPaymentTypes().then((res) => {
            setPaymentTypes(res);
        })
    }, [])


    const handleCarNumber = () => {
        const carNumber = car_number;
        if (carNumber === "") {
            toast.error('Please enter the car number', {
                duration: 5000,
                className: 'toast-message',
            });
            inputRef.current.focus();
            return false
        }
        getDriverInfoMutation.mutate(carNumber.toLocaleUpperCase());
    }

    const getDriverInfoMutation = useMutation(findDriverByCarNumber, {
        onSuccess: async (res) => {
            if (!res.success) {
                toast.error(res.message, {
                    duration: 5000,
                    className: 'toast-message',
                });
                inputRef.current.focus();
                return false
            } else {
                const full_name = res.driver.first_name + " " + res.driver.last_name
                const driverCode = res.driver.driver_code;
                if (driverCode) {
                    setDriverCode(driverCode)
                } else {
                    setNewDriverCode(driverCode)
                }
                setDriverId(res.driver.id)
                setFullname(full_name)
                setMobileNumber(res.driver.mobile_number)
                return true;
            }
        },
        onError: async (err) => {
            toast.error(err.message, {
                duration: 5000,
                className: 'toast-message'
            });
            inputRef.current.focus();
            return false
        }
    })

    const handlePaymentType = (e) => {
        const payType = e.target.value.toLocaleUpperCase();
        const chkData = {
            driver_code: driver_code.toLocaleUpperCase(),
            car_number: car_number.toLocaleUpperCase(),
            payment_type: payType,
        }
        if (payType === "SS" || payType === "DP") {
            checkRegFeePayment(chkData).then((res) => {
                if (res.status && payType === "SS") {
                    setPaymentType(payType)
                    setIsSubscription(true)
                    return true
                }
                if (res.status && payType === "DP") {
                    setPaymentType(payType)
                    setIsSubscription(false)
                    return true
                }
                if (!res.status) {
                    toast.error(res.message, {
                        duration: 3000,
                        className: 'toast-message'
                    })
                    setPaymentType(payType)
                    setIsSubscription(false)
                    inputRef.current.focus();
                    return false
                }
            })
        } else {
            setPaymentType(payType)
            return true
        }
    }

    const handleOnChange = (value, countryObj, e, formattedValue) => {
        if (country === countryObj?.name) {
            setMobileNumber(formattedValue);
        } else {
            setMobileNumber('');
            setCountry(countryObj?.countryCode);
        }
    };


    const handleTransactionID = (e) => {
        e.preventDefault();
        let trans_id_error = ""
        const trans_id = e.target.value
        // check null transaction ID
        if (!trans_id) {
            console.log("Empty transaction ID")
            trans_id_error = 'Transaction ID cannot be empty.'
            toast.error(trans_id_error, {
                duration: 3000,
                className: 'toast-message'
            })
            return false
        } else
            // After null checking transaction ID, check length
            if (trans_id.trim() < 11) {
                console.log("Transaction ID less than 11 characters")
                trans_id_error = 'You have entered wrong Transaction ID. Transaction ID is 11 characters.'
                toast.error(trans_id_error, {
                    duration: 5000,
                    className: 'toast-message'
                })
                return false
            } else {
                console.log("Transaction ID accepted")
                setTransactionId(trans_id)
                return true
            }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let trans_id_error = ""
        const chkData = {
            driver_code: driver_code.toLocaleUpperCase(),
            car_number: car_number.toLocaleUpperCase(),
            payment_type: payment_type,
        }

        if (!payment_type) {
            toast.error("Please select payment type", {
                duration: 3000,
                className: 'toast-message'
            })
            return false
        }
        if (payment_type === "SS" || payment_type === "DP") {
            checkRegFeePayment(chkData).then((res) => {
                if (!res.status) {
                    toast.error(res.message, {
                        duration: 3000,
                        className: 'toast-message'
                    })
                    inputRef.current.focus();
                    return false
                } else {
                    return true
                }
            })
        }

        // check null transaction ID
        if (!transaction_id) {
            trans_id_error = 'Please enter Transaction ID.'
            toast.error(trans_id_error, {
                duration: 3000,
                className: 'toast-message'
            })
            return false
        }
        // After null checking transaction ID, check length
        if (transaction_id.length < 11) {
            trans_id_error = 'Wrong Transaction ID. Transaction ID should be 11 characters.'
            toast.error(trans_id_error, {
                duration: 5000,
                className: 'toast-message'
            })
            return false
        }

        const paymentData = {
            driver_id: driver_id,
            car_number: car_number.toLocaleUpperCase(),
            fullname: fullname.toLocaleUpperCase(),
            driver_code: driver_code ? driver_code.toLocaleUpperCase() : new_driver_code,
            mobile_number: mobile_number,
            payment_type: payment_type.toLocaleUpperCase(),
            payment_year: payment_year,
            payment_month: payment_month.toLocaleUpperCase(),
            amount: parseFloat(amount).toFixed(2),
            transaction_id: transaction_id.toLocaleUpperCase(),
        };
        addDriverPaymentMutation.mutate(paymentData);
    }

    const addDriverPaymentMutation = useMutation(addPayment, {
        onSuccess: async (res) => {
            if (res.success === false) {
                toast.error(res.message, {
                    duration: 5000,
                    className: 'toast-message'
                });
                inputRef.current.focus();
                return false
            }
            if (res.success === true) {
                toast.success(res.message, {
                    duration: 5000,
                    className: 'toast-message'
                });
            }
            setCarNumber("")
            setFullname("")
            setDriverCode("")
            setMobileNumber()
            setPaymentType("")
            setPaymentYear("")
            setPaymentMonth("")
            setAmount("")
            setTransactionId("")
            setCountry("gh");
            navigate(from, { replace: true });
        },
        onError: async (err) => {
            toast.error(err.message, {
                duration: 5000,
                className: 'toast-message',
            })
            inputRef.current.focus();
            return false
        }
    })


    const handleCancel = () => {
        navigate('/')
    }

    const paymentForm =
        <div className="payment-container">
            <form onSubmit={handleSubmit}>
                <div className="form-group row">
                    <fieldset className="col-sm-6">
                        <div className='form-group row'>
                            <label htmlFor="car_number" className="col-sm-3">Car Number<span>&nbsp;&nbsp;*</span></label>
                            <div className="col-sm-9">
                                <input
                                    ref={inputRef}
                                    type="text"
                                    name='car_number'
                                    id='car_number'
                                    placeholder='Car Number'
                                    style={{
                                        height: "40px",
                                        textTransform: "uppercase",
                                    }}
                                    required
                                    value={car_number}
                                    onChange={(e) => setCarNumber(e.target.value)}
                                    onBlur={handleCarNumber}
                                    className="form-control" />
                            </div>
                        </div>
                        <div className='form-group row'>
                            <label htmlFor="driver_code" className="col-sm-3">Driver Code<span>&nbsp;&nbsp;*</span></label>
                            <div className="col-sm-9">
                                <input
                                    type="text"
                                    name='driver_code'
                                    id='driver_code'
                                    style={{
                                        height: "40px",
                                        fontWeight: "bold",
                                        textTransform: "uppercase",
                                    }}
                                    disabled
                                    value={driver_code}
                                    onChange={(e) => setDriverCode(e.target.value)}
                                    className="form-control" />
                            </div>
                        </div>
                        <div className='form-group row'>
                            <label htmlFor="fullname" className="col-sm-3">Full Name<span>&nbsp;&nbsp;*</span></label>
                            <div className="col-sm-9">
                                <input
                                    type="text"
                                    name='fullname'
                                    id='fullname'
                                    placeholder='FullName'
                                    style={{
                                        height: "40px",
                                        fontWeight: "bold",
                                        textTransform: "uppercase",
                                    }}
                                    disabled
                                    autoComplete='off'
                                    value={fullname}
                                    onChange={(e) => setFullname(e.target.value)}
                                    className="form-control" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="mobile_number" className="col-sm-3 col-form-label">Mobile Number<span>&nbsp;&nbsp;*</span></label>
                            <div className="col-sm-9">
                                <PhoneInput
                                    type="tel"
                                    id="mobile_number"
                                    inputStyle={{
                                        width: "100%",
                                        height: "40px"
                                    }}
                                    disabled={true}
                                    countryCodeEditable={false}
                                    country={country}
                                    enableSearch={true}
                                    value={mobile_number}
                                    name="mobile_number"
                                    onChange={(value, countryObj, e, formattedValue) =>
                                        handleOnChange(value, countryObj, e, formattedValue)
                                    }
                                />
                            </div>
                        </div>
                        <div className='form-group row'>
                            <label htmlFor="payment_type" className="col-sm-3">Payment Type<span>&nbsp;&nbsp;*</span></label>
                            <div className="col-sm-9">
                                <select
                                    type="text"
                                    name="payment_type"
                                    id="payment_type"
                                    style={{
                                        height: "40px",
                                        textTransform: "uppercase",
                                    }}
                                    // required
                                    value={payment_type}
                                    onChange={handlePaymentType}
                                    className="form-control">
                                    <option value="">Payment Type</option>
                                    {payment_types?.map((type) =>
                                        <option key={type.id} value={type.type_code}>
                                            {type.description}
                                        </option>
                                    )}
                                </select>
                            </div>
                        </div>
                        {isSuscription ?
                            <>
                                <div className='form-group row'>
                                    <label htmlFor="payment_year" className="col-sm-3">Payment Year<span>&nbsp;&nbsp;*</span></label>
                                    <div className="col-sm-9">
                                        <select
                                            type="text"
                                            name='payment_year'
                                            id='payment_year'
                                            style={{
                                                height: "40px",
                                                textTransform: "uppercase",
                                            }}
                                            required
                                            value={payment_year}
                                            onChange={(e) => setPaymentYear(e.target.value)}
                                            className="form-control"
                                        >
                                            <option>Year</option>
                                            {years.map((year, index) => <option value={year} key={`year${index}`}>{year}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className='form-group row'>
                                    <label htmlFor="payment_month" className="col-sm-3">Payment Month<span>&nbsp;&nbsp;*</span></label>
                                    <div className="col-sm-9">
                                        <select
                                            type="text"
                                            id='payment_month'
                                            style={{
                                                height: "40px",
                                                textTransform: "uppercase",
                                            }}
                                            name='payment_month'
                                            required
                                            value={payment_month}
                                            onChange={(e) => setPaymentMonth(e.target.value)}
                                            className="form-control"
                                        >
                                            <option>Month</option>
                                            <option value={"January"}>January</option>
                                            <option value={"February"}>February</option>
                                            <option value={"March"}>March</option>
                                            <option value={"April"}>April</option>
                                            <option value={"May"}>May</option>
                                            <option value={"June"}>June</option>
                                            <option value={"July"}>July</option>
                                            <option value={"August"}>August</option>
                                            <option value={"September"}>September</option>
                                            <option value={"October"}>October</option>
                                            <option value={"November"}>November</option>
                                            <option value={"December"}>December</option>
                                        </select>
                                    </div>
                                </div>
                            </>
                            : null}
                        <div className='form-group row'>
                            <label htmlFor="amount" className="col-sm-3">Amount<span>&nbsp;&nbsp;*</span></label>
                            <div className="col-sm-9">
                                <input
                                    type="number"
                                    name='amount'
                                    id='amount'
                                    style={{
                                        height: "40px",
                                        textTransform: "uppercase",
                                    }}
                                    placeholder='Amount'
                                    required
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                    className="form-control" />
                            </div>
                        </div>
                        <div className='form-group row'>
                            <label htmlFor="transaction_id" className="col-sm-3">MoMo Transaction ID<span>&nbsp;&nbsp;*</span></label>
                            <div className="col-sm-9">
                                <input
                                    type="text"
                                    id='transaction_id'
                                    style={{
                                        height: "40px",
                                        textTransform: "uppercase",
                                    }}
                                    placeholder='MoMo Transaction ID'
                                    name='transaction_id'
                                    // required
                                    maxLength={11}
                                    value={transaction_id}
                                    // onChange={handleTransactionID}
                                    onChange={(e) => setTransactionId(e.target.value)}
                                    className="form-control" />
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div className="panel-footer">
                    <div className='form-group row'>
                        <div className="col-sm-1">
                            <button type="submit" className='btn btn-success btn-block'>Submit</button>
                        </div>&nbsp;&nbsp;&nbsp;&nbsp;
                        <div className="col-sm-1">
                            <button type="button" className='btn btn-danger btn-block' onClick={handleCancel}>Cancel</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>


    return (
        <>
            <section className="content-header">
                <h1><i className="fas fa-bank"></i> Payments</h1>
                <Toaster toastOptions={{ position: "top-center" }} />
            </section>
            <section className="content">
                <div className="box">
                    <div className="box-body">
                        <div className="panel panel-default panel-br-4">
                            <div className="panel-heading">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h4 className="panel-title">
                                            <span>Driver Payments</span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-body">
                                {paymentForm}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AdminPayments