import useAuth from "../../../hooks/useAuth";
import { adminLogout } from "../../../api/apiServices"
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Logout = () => {
    const { setAuth } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        setAuth({});
        try {
            adminLogout();
            navigate('/login');
        } catch (err) {
            console.error(err);
        }

    }, [setAuth, navigate]);

}

export default Logout;
